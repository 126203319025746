.container {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.9);
}

.button {
  position: absolute;
  z-index: 21;
  top: 150px;
  right: 20px;
  color: aliceblue;
  background-color: transparent;
  border: none;
}

.videoContainer {
  position:absolute;
  top:100px;
  left: 0;
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player{
  width: 70%;
  height: 70%;
  & > div{
    height: 100%;
  }
}

.icon {
  fill: currentColor;
  cursor: pointer;
}