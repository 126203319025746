.container {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 60px;
  background-image: url(../../images/quality/srction10background.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.containerMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
  position: relative;
  min-height: 550px;
  height: 100%;
  width: 100%;
  padding-top: 30px;
  background-image: url(../../images/quality/mobile/background.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.containerTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  min-height: 800px;
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(../../images/quality/tablet/background.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.wrapper {
  position: relative;
  margin-left: calc(50% - 165px);
  margin-top: 34px;
  width: 331px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 754px;
    margin-left: calc(50% - 375px);
    margin-top: 45px;
  }

  @media screen and (min-width: 1300px) {
    margin-left: calc(50% - 500px);
    margin-top: 63px;
    width: 1240px;
  }
}

.qualityLeftPart {
  display: flex;
  flex-direction: column;
  justify-content: top;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    padding: 29px;
    box-sizing: border-box;
    width: 754px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(7.5px);
    margin-bottom: 50px;
  }

  @media screen and (min-width: 1300px) {
  }
}
.title {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 217px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: fit-content;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 35px;
    line-height: 37px;

    color: var(--main_color);
    width: fit-content;
  }
}
.textWrapper {
  width: 331px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 357px;
  }

  @media screen and (min-width: 1300px) {
    width: 600px;

    padding: 52px 218px 52px 50px;

    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
  }
}

.text {
  font-family: 'Titillium Web';
  font-size: 18px;
  font-weight: 400;
  line-height: 111%;
  color: #1d1d1d;
}
.textLineOne,
.textLineThree {
  font-weight: 400;
}
.textLineOne {
  margin-bottom: 20px;
}

.textLineFour {
  font-weight: 700;
  color: #111111;
}
.textLineTwo {
  font-weight: 400;
  color: #111111;
  margin-top: 2px;
}
.textLineThree {
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    width: 460px;
    margin-bottom: 30px;
  }
}

.textLineFour {
  margin-top: 10px;
}
.button {
  box-sizing: border-box;
  margin-top: 50px;
  margin-bottom: 70px;
  padding: 12px 20px 12px 20px;
  width: 266px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Titillium Web';
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  border: 1px solid #ffffff;
  transition: all 450ms cubic-bezier(0.25, 1, 0.33, 1);
  &:hover,
  &:focus {
    color: #1d1d1d;
    background: var(--accent_color);
    border: 1px solid var(--accent_color);
    box-shadow: 0px 0px 8px rgba(120, 29, 149, 0.3);
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-left: 20px;
  }

  @media screen and (min-width: 1300px) {
    margin-left: 50px;
    margin-top: 73px;
    margin-bottom: 130px;
    padding: 10px 28px 10px 28px;
    width: 290px;
  }
}

.linkId {
  position: absolute;
  top: -100px;
}

.containerWrapper {
  margin-left: calc(50% - 180px);
  margin-top: 60px;
  display: flex;
  align-items: center;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-left: calc(50% - 360px);
  }

  @media screen and (min-width: 1300px) {
    margin-left: calc(50% - 400px);
    display: flex;
    align-items: center;
    margin-top: 0;
  }
}

.line {
  width: 331px;
  border-top: 2px solid #781d95;
  margin-top: 22px;
  margin-bottom: 22px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 357px;
  }

  @media screen and (min-width: 1300px) {
    border-top: 2px solid #781d95;
    width: 403px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.certificate {
  top: -29px;
  right: 0;
  z-index: 100;
  position: absolute;
  transition: 300ms linear;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 50px;
  }

  @media screen and (min-width: 1300px) {
    right: 130px;
    top: -15px;
  }
  &:hover {
    scale: 1.05;
  }
}
