.header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1240px;
  width: 100%;
  box-sizing: border-box;
}

.wrapper {
  @media screen and (max-width: 1299px) {
    display: none;
  }
  z-index: 10001;
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.wrapperMobile {
  @media screen and (min-width: 1300px) {
    display: none;
  }

  z-index: 10001;
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  height: 60px;
  width: calc(100%);
}

.mainHeader {
  height: 100%;
  width: 100%;
  padding: 0px 90px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  width: 100%;
  height: 120px;
}

.lightThemeText {
  color: #676767;
  transition: color 600ms cubic-bezier(0.25, 1, 0.33, 1);
  @media screen and (min-width: 1300px) {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 18px;
  }
  &:hover {
    color: var(--accent_color);
  }
}

.darkThemeText {
  color: #e6e8ec;
  transition: color 600ms cubic-bezier(0.25, 1, 0.33, 1);

  @media screen and (min-width: 1300px) {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 18px;
  }

  &:hover {
    color: var(--accent_color);
  }
}

.lightThemeIcon {
  color: #676767;
}

.lightThemeIconAccent {
  color: var(--accent_color);
}

.darkThemeIcon {
  color: #e6e8ec;
}

.darkThemeIconAccent {
  color: var(--accent_color);
}

.lightThemeBackground {
  background-color: #e6e8ec;
  transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
}

.darkThemeBackground {
  background: linear-gradient(
    90deg,
    rgba(120, 29, 149, 0.2) 0.72%,
    rgba(21, 32, 117, 0.2) 100.08%
  );
  backdrop-filter: blur(15px);
  transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
}

.linkWrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
.linkWrapperMenu {
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100%);
  max-width: 754px;
  height: calc(100vh + 20px);
  background: rgba(29, 29, 29, 0.7);
  // backdrop-filter: blur(20px);
  transform: translateX(0);
  transition: all 1000ms cubic-bezier(0.25, 1, 0.33, 1);
}

.linkList {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.linkListMenu {
  height: fit-content;
  margin-bottom: 40px;
}
.item {
  position: relative;
  align-items: center;
  display: flex;
}

.link {
  cursor: pointer;
  padding: 20px;

  height: 100%;

  @media screen and (max-width: 1299px) {
    padding-left: 0;
    border-bottom: solid 2px #d9d9d9;
    width: calc(100% - 65px);
    margin-left: 20px;
    padding-bottom: 0;
  }

  @media screen and (min-width: 1300px) {
    cursor: pointer;
    padding: 25px;
    padding-left: 16px;
    padding-right: 16px;
    height: 100%;
  }
}

.title {
  margin-bottom: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  @media screen and (min-width: 1300px) {
    font-family: 'Roboto';
    font-style: normal;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }
}
.titleDark {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #1d1d1d;
  margin-bottom: 15px;
}

.containerTitle {
  display: flex;
  align-items: center;
  height: 100%;

  &:hover .containerArrowBottom {
    display: block;
    transition: all 100ms;
  }
  &:hover .containerArrowBottomDark {
    display: block;
    transition: all 100ms;
  }
}

.iconArrow {
  position: relative;
  fill: currentColor;
  right: 0;
  top: 1px;
  transform: rotate(180deg) translate(-3px, 6px);
}
.iconArrowMenu {
  padding-right: 25px;
  padding-top: 15px;
  position: absolute;
  right: 30px;
  top: 15px;
  fill: #fff;
  transform: rotate(180deg) translate(0px, 6px);
}
.iconArrowMenuTop {
  padding-left: 25px;
  padding-bottom: 15px;
  position: absolute;
  right: 30px;
  top: 15px;
  fill: #fff;
}
.headerRightPart {
  width: 234px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e6e8ec;
}

.languageSelector {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding-right: 5px;
  text-align: right;
  cursor: pointer;
}

.icon {
  transform: translateY(0px);
  transition: all 300ms;
  fill: currentColor;
  padding: 5px;
}

.iconAccent {
  transform: translateY(0px);
  transition: all 300ms;
  padding: 5px;
  fill: currentColor;
}

.socialWrapper {
  @media screen and (max-width: 833px) {
    display: none;
  }
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.numberTel {
  position: absolute;
  top: 15px;
  height: 20px;
  right: 110px;
}

.numberPhone {
  font-family: 'Titillium Web';
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--accent_color);
}

.containerIcon {
  cursor: pointer;
  &:hover .containerEmail {
    opacity: 1;
    transform: translateY(0);
    transition: all 300ms;
  }
  &:hover .iconMail {
    transform: translateY(-30px);
    transition: all 300ms;
  }
  &:hover .iconPhone {
    transform: translateY(-30px);
    transition: all 300ms;
  }
  &:hover .iconLinkedin {
    transform: translateY(-30px);
    transition: all 300ms;
  }
  &:hover .iconLinkedinAccent {
    transform: translateY(-30px);
    transition: all 300ms;
  }
  &:hover .iconPhoneAccent {
    transform: translateY(-30px);
    transition: all 300ms;
  }
  &:hover .iconMailAccent {
    transform: translateY(-30px);
    transition: all 300ms;
  }
}

.containerHidden {
  text-align: right;
  height: 30px;
  overflow: hidden;
}
.containerArrowBottom {
  transition: all 100ms;
  display: none;
  position: absolute;
  width: 165px;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.8);
  margin-left: -4px;
  top: 50px;
  padding: 8px;
}

.menuBackground {
  width: 200px;
  height: fit-content;

  @media screen and (min-width: 1300px) {
    padding: 8px;
  }
}
.containerArrowBottomDark {
  transition: all 100ms;
  display: none;
  position: absolute;
  width: 165px;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.3);
  margin-left: -4px;
  top: 50px;
  padding: 8px;
}

.textArrow {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  padding: 5px;
  padding-left: 10px;

  &:hover {
    border-radius: 2px;
    color: #1d1d1d;
    background-color: var(--accent_color);
  }
  @media screen and (min-width: 1300px) {
    color: #1d1d1d;
  }
}
.textArrowDark {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  padding: 5px;
  padding-left: 10px;

  &:hover {
    border-radius: 2px;
    color: #1d1d1d;
    background-color: var(--accent_color);
  }
}

.textMenu {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  border-top: 1px solid #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--accent_color);
}

.menuItem:not(:last-child) {
  display: block;
  margin-bottom: 8px;
}

.bottomContainer {
  @media screen and (max-width: 1299px) {
    width: 115px;
    height: 38px;
    top: 47px;
    right: 63px;
  }
  position: absolute;
  top: 80px;
  right: 118px;
  border-radius: 4px;
}
.bottomContainerAccent {
  margin-left: 20px;
  border-radius: 4px;
  height: 50px;
}
.buttonQuote {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 109px;
  height: 34px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #1d1d1d;
  text-transform: uppercase;
  background-color: var(--accent_color);
  border: none;
  cursor: pointer;

  transition: all 250ms cubic-bezier(0.25, 1, 0.33, 1);

  &:hover,
  &:focus {
    color: #fff;
    background-color: var(--accent_color);
  }
  &:active {
    width: 115px;
    height: 38px;
    background: #e8a0ff;
    border: 2px solid #b66fcd;
    box-shadow: 0px 0px 8px rgba(120, 29, 149, 0.3);
  }

  @media screen and (min-width: 1300px) {
    width: 115px;
    height: 38px;
  }
}

.buttonQuoteAccent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 109px;
  height: 34px;
  font-family: 'Titillium Web';
  font-style: normal;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #1d1d1d;
  background-color: var(--accent_color);
  border: none;
  cursor: pointer;

  transition: all 250ms cubic-bezier(0.25, 1, 0.33, 1);

  &:hover,
  &:focus {
    color: #fff;
    background-color: var(--accent_color);
  }
  &:active {
    width: 115px;
    height: 38px;
    background: #e8a0ff;
    border: 2px solid #b66fcd;
    box-shadow: 0px 0px 8px rgba(120, 29, 149, 0.3);
  }
  @media screen and (min-width: 1300px) {
    width: 115px;
    height: 38px;
  }
}

.containerMenu {
  display: flex;
  align-items: center;
  width: 157px;
  justify-content: space-between;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 280px;
  }
}

.line {
  width: calc(100% - 40px);
  margin-top: 110px;
  margin-left: 20px;
  border-top: solid 2px #d9d9d9;
}

.iconClose {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
}

.iconMenu {
  fill: currentColor;
  cursor: pointer;
  margin-right: 20px;
  color: #676767;
}
.iconMenuDark {
  fill: currentColor;
  cursor: pointer;
  margin-right: 20px;
  color: #fff;
}

.logoImg {
  margin-left: 20px;
}

.linkList {
  height: 50px;
  width: 100%;
}
.linkSocial {
  color: #fff;
}
.itemSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #781d95;
  transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: var(--accent_color);
    box-shadow: drop-shadow(0px 0px 4px rgba(120, 29, 149, 0.8));
    & .icon {
      color: var(--main_color);
      transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
    }
  }
}
.linkListSocial {
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
}
