.mainContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.linkAbout {
  position: absolute;
  top: -100px;
}
.containerVideo {
  width: 100%;
  min-height: 950px;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../../images/about/mobile/background.jpg);

  @media screen and (min-width: 834px) {
    width: 100%;
    height: 100vh;
    min-height: 800px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../images/about/tablet/background.jpg);
  }
  @media screen and (min-width: 1300px) {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../images/about/background.jpg);
  }
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 501;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.8);
}
.wrapperBackdrop {
  position: relative;
  padding: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 554px;
  height: 190px;
  background-color: black;
  border: 1px dashed #781d95;
}
.backdropText {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
}
.iconMenu {
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
}

.title {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 266px;
  @media screen and (min-width: 834px) {
    font-size: 26px;
    line-height: 30px;
    width: 453px;
  }
  @media screen and (min-width: 1300px) {
    width: 615px;
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
  }
}

.container {
  position: absolute;
  top: 30px;
  z-index: 2;
  left: calc(50% - 400px / 2);

  @media screen and (min-width: 833px) {
    position: absolute;
    top: 30px;
    z-index: 2;
    left: calc(50% - 754px / 2);
    width: 754px;
  }
  @media screen and (min-width: 1300px) {
    position: absolute;
    top: 30px;
    z-index: 2;
    left: calc(50% - 1240px / 2);
    width: 1240px;
  }
}

.video {
  width: 100%;
}

.tabsImg {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.titleSecond {
  font-family: TitilliumWeb;
  font-size: 36px;
  line-height: 36px;
  font-weight: 600;
  margin-left: 15px;
  color: var(--accent_color);
}

.containerImg {
  margin-bottom: 40px;
  display: flex;
}

.icon {
  fill: var(--accent_color);
}

.text {
  margin: 0px 0px 100px 50px;
  font-family: Titillium Web;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  text-align: justify;
}

.refText {
  text-decoration: none;
  color: inherit;
  &:hover,
  &:focus {
    color: var(--accent_color);
  }
}

.containerText {
  margin: 0px 0px 100px 50px;
}

.textNext {
  margin-bottom: 20px;
  font-family: Titillium Web;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  @media screen and (max-width: 833px) {
  }
}

.containerMobileAccent {
  @media screen and (max-width: 833px) {
    width: 350px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1300px) {
    display: none;
  }
}
.contentMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
}
.contentTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
}

.containerMobileDescription {
  margin-top: 30px;
}

.containerMobileDescription1 {
  margin-top: 20px;
}

.containerMobileDescription2 {
  margin-top: 10px;
}
.containerMobileDescription3 {
  margin-top: 65px;
}
