.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10009;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
}
.containerImg {
  position: absolute;
  z-index: 10012;
}
.containerLoader {
  position: relative;
  width: 200px;
  height: 80px;
  overflow: hidden;
}

.loader {
  position: absolute;
  top: 0;
  z-index: 10011;
  width: 200px;
  height: 80px;
  background: linear-gradient(180deg, rgb(119, 29, 148), rgb(25, 32, 118));
  animation: pulse 60s;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    transform: translateX(-100%);
  }

  20% {
    transform: translateX(17%);
  }

  100% {
    transform: translateX(100%);
  }
}

.buttonShow {
  @media screen and (max-width: 1299px) {
    display: none;
  }
  position: fixed;
  bottom: 0;
  left: 50px;
  background-color: #e8a0ff;
  padding: 6px 10px;
  border: none;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 8px;
  text-align: center;
  color: #000;
  z-index: 10008;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: 200ms;
  cursor: pointer;
  &:hover {
    padding-bottom: 15px;
    color: #fff;
  }
}
