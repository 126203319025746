.commonWrapper {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: relative;
  width: 100%;
  min-height: 1000px;
  background-color: rgb(23, 22, 22);
}

.commonWrapperMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
  min-height: 1400px;
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 60px;
  background-image: url(../../images/contacts/mobile/background.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.commonWrapperTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  min-height: 1300px;
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 30px;
  background-image: url(../../images/contacts/tablet/back.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

@media screen and (min-width: 834px) and (max-width: 1299px) {
}

@media screen and (min-width: 1300px) {
}
.container {
  position: relative;
}

.img {
  display: block;
  width: 100%;
}

.map {
  width: 100%;
  min-height: 1000px;
  background-color: #343434;
}

.my-div-icon {
  position: relative;
}

.blob {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(231, 158, 255, 0.6);
  border-radius: 50%;
  height: 20px;
  width: 20px;

  box-shadow: 0 0 0 0 rgba(231, 158, 255, 0.6);
  transform: scale(1.2);
  animation: pulse 2s infinite;
}

.point {
  position: absolute;
  z-index: 5;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--accent_color);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(231, 158, 255, 0.5);
  }

  70% {
    transform: scale(3);
    box-shadow: 0 0 0 10px rgb(231, 158, 255, 0.5);
  }

  100% {
    transform: scale(1);

    box-shadow: 0 0 0 0 rgba(231, 158, 255, 0.5);
  }
}

.form {
  z-index: 500;
  position: absolute;

  left: calc(50% - 331px / 2);
  top: 180px;
  width: 331px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    left: calc(50% - 754px / 2);
    top: 180px;
    width: 754px;
    background: rgba(29, 29, 29, 0.6);
    backdrop-filter: blur(12px);
    padding: 40px;
    box-sizing: border-box;
    margin-top: 34px;
  }

  @media screen and (min-width: 1300px) {
    padding: 55px 50px;
    left: calc(50% - 1240px / 2);
    top: 180px;
    width: 416px;
    background: rgba(29, 29, 29, 0.2);
    backdrop-filter: blur(9px);
    margin-right: 40px;
  }
}

.link {
  position: absolute;
  top: -100px;
  background-color: transparent;
  width: 0;
  height: 0;
}

.title {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 30px;
    line-height: 22px;
  }
}

.textAccentLink {
  display: none;
  @media screen and (min-width: 1300px) {
    display: block;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    color: #e8a0ff;
    width: 200px;
    margin-left: 38px;
    font-size: 13px;
    line-height: 16px;
    text-decoration: underline;
  }
}

.textAccent {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    width: 200px;
    margin-left: 38px;
    font-size: 13px;
    line-height: 16px;
  }
}

.text {
  display: flex;
  align-items: center;
  font-family: TitilliumWeb;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #e8a0ff;
}

.textarea_label {
  font-family: TitilliumWeb;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #e8a0ff;
  @media screen and (min-width: 1300px) {
    line-height: 0px;
  }
}

.line {
  margin-bottom: 30px;
  background: rgba(12, 12, 13, 0.7);
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2);
  color: #ffffff;

  :global {
    .PhoneInput--focus {
      outline: none;
    }

    .PhoneInputInput {
      font-family: TitilliumWeb;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      padding: 5px 10px;
      border: none;
      width: 416px;
      height: 30px;
      &::placeholder {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 11px;
        line-height: 18px;
        color: #ffffff;
      }
    }

    .PhoneInput {
      margin: 2px 0 2px 0;
      padding-left: 10px;
    }
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    margin-bottom: 10px;
  }
}

.globeHide {
  :global {
    .PhoneInputCountryIcon {
      display: none;
    }
  }
}

.lineAccent {
  margin-bottom: 20px;
}

.iconWrapper {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.iconUpload {
  margin-right: 20px;
}

.icon {
  margin-right: 10px;
}
.bottomContainer {
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    display: flex;
    margin-top: 16px;
    align-items: center;
  }
}

.button {
  cursor: pointer;
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #fbfbfb;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #111111;
  border-radius: 5px;
  border: none;
}
.buttonQuote {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  backdrop-filter: blur(10px);
  border: 1px solid #ffffff;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.3);
  transition: all 450ms cubic-bezier(0.25, 1, 0.33, 1);
  margin-top: 40px;
  width: 211px;
  height: 40px;

  @media screen and (min-width: 1300px) {
    line-height: 17px;
    margin-top: 0px;
    width: 162px;
    height: 38px;
  }
}
.buttonQuoteActive {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  backdrop-filter: blur(10px);
  border: 1px solid #ffffff;
  text-transform: uppercase;
  transition: all 450ms cubic-bezier(0.25, 1, 0.33, 1);
  width: 211px;
  height: 40px;
  background: rgba(255, 255, 255, 0.3);
  margin-top: 40px;
  box-shadow: 0px 0px 8px rgba(120, 29, 149, 0.3);
  @media screen and (min-width: 1300px) {
    line-height: 17px;
    margin-top: 0px;
    width: 162px;
    height: 38px;
  }
  &:hover,
  &:focus {
    border: 1px solid #e8a0ff;
    color: #1d1d1d;
    background: #e8a0ff;
    box-shadow: 0px 2px 10px 2px rgba(63, 68, 71, 0.18);
  }
  &:active {
    background: #e8a0ff;
    border: 2px solid #b66fcd;
    box-shadow: 0px 0px 8px rgba(120, 29, 149, 0.3);
  }
}

.input {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 13px;
  padding: 16px 14px;
  border: none;
  width: 416px;
  background: rgba(12, 12, 13, 0);
  color: #ffffff;
  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 13px;
    color: #ffffff;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 674px;
  }
}

.input:focus-visible {
  outline: none;
}

.textarea {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 13px;
  background: rgba(12, 12, 13, 0.7);
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2);
  width: 331px;
  box-sizing: border-box;
  padding: 14px;
  height: 160px;
  margin-top: 5px;
  color: #ffffff;
  border: none;
  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 674px;
  }

  @media screen and (min-width: 1300px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    width: 416px;
    padding: 10px;
    height: 80px;
    margin-top: 5px;
    color: #ffffff;
  }
}

.textarea:focus-visible {
  outline: none;
}

.textarea:focus,
.input:focus {
  border: 2px solid #e8a0ff;
}

.contactDetails {
  z-index: 500;
  position: absolute;
  top: 700px;
  width: 331px;

  @media screen and (max-width: 833px) {
    left: calc(50% - 166px);
    top: 780px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    display: flex;
    width: 758px;
    top: 930px;
    left: calc(50% - 758px / 2);
    justify-content: space-between;
  }

  @media screen and (min-width: 1300px) {
    z-index: 500;
    position: absolute;
    right: calc(50% - 500px);
    padding: 20px 20px 20px 20px;
    background: rgba(52, 52, 52, 0.5);
    backdrop-filter: blur(9px);
    width: 408px;
    top: 300px;
  }
}

.contactDetailsDe {
  z-index: 500;
  position: absolute;
  top: 700px;
  width: 331px;

  @media screen and (max-width: 833px) {
    left: calc(50% - 166px);
    top: 800px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    display: flex;
    width: 758px;
    top: 930px;
    left: calc(50% - 758px / 2);
    justify-content: space-between;
  }

  @media screen and (min-width: 1300px) {
    z-index: 500;
    position: absolute;
    right: calc(50% - 500px);
    padding: 20px 20px 20px 20px;
    background: rgba(52, 52, 52, 0.5);
    backdrop-filter: blur(9px);
    width: 408px;
    top: 300px;
  }
}

.h2 {
  font-family: TitilliumWeb;
  font-size: 42px;
  font-weight: 700px;
  color: var(--accent_color);
  margin-bottom: 20px;
}

.contactRight,
.contactCentral {
  display: inline-block;
  align-self: flex-end;
}
.contactCentralTablet {
  display: flex;
  margin-top: 24px;
}

.contactCentral {
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 24px;
  }
}
.contactLeft {
  width: 42%;
}
.contactCentral {
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    width: 50%;
  }
}

.contactText,
.contactTitle,
.contactTel,
.contactFax,
.contactMail {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-right: 10px;

  @media screen and (min-width: 1300px) {
    font-family: Titillium Web;
    font-size: 18px;
    font-weight: 400;
    color: #e6e8ec;
  }
}

.contactTel {
  margin-bottom: 24px;
}
.contactText {
  cursor: pointer;
  @media screen and (max-width: 833px) {
    width: 215px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 215px;
  }
}

.contactTextMobile {
  font-family: 'Titillium Web';
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
}

.containerFlexLeft {
  @media screen and (max-width: 833px) {
    margin-right: 15px;
  }
}
.contactInfo {
  font-family: 'Titillium Web';
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
  @media screen and (max-width: 833px) {
    &:first-of-type {
      margin-bottom: 14px;
    }
  }
}

.mailLink {
  display: block;
  font-family: 'Titillium Web';
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
}
.contactTelTablet,
.contactFaxTelTablet {
  margin-bottom: 14px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
}

.contactFax {
  @media screen and (min-width: 1300px) {
    margin-bottom: 15px;
  }
}
.contactMail:not(:last-of-type),
.contactMail2:not(:last-of-type) {
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-bottom: 14px;
    margin-right: 15px;
  }
  @media screen and (min-width: 1300px) {
    margin-bottom: 10px;
  }
}
.contactMail2 {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: #ffffff;
}
.contactTelAccent,
.contactFaxAccent {
  font-family: 'Titillium Web';
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  @media screen and (max-width: 833px) {
    font-style: normal;
    display: flex;
    align-items: center;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-bottom: 14px;
    margin-left: 15px;
  }
}

.inputText {
  &:focus-visible {
    outline: none;
  }
}

.containerContactText {
  display: flex;
  margin-top: 20px;
}
.containerWrapper {
  position: absolute;
  top: 60px;
  left: calc(50% - 155px);
  z-index: 505;
  @media screen and (max-width: 833px) {
    top: 60px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    left: calc(50% - 220px);
    top: 57px;
  }

  @media screen and (min-width: 1300px) {
    left: calc(50% - 1240px / 2);
    top: 60px;
  }
}

.line12 {
  width: 331px;
  margin-top: 40px;
  margin-bottom: 40px;
  border-top: 2px solid #781d95;
}
.line1 {
  @media screen and (max-width: 833px) {
    width: 331px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  border-top: 2px solid #781d95;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @media screen and (min-width: 1300px) {
    margin-top: 18px;
    margin-bottom: 18px;
  }
}

.contactDetailsLeft {
  height: 228px;
  width: 331px;
  border-top: 2px solid #781d95;
  border-bottom: 2px solid #781d95;
  box-sizing: border-box;
  padding-top: 72px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    padding-top: 56px;
  }
}

.contactDetailsRight {
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    display: flex;
    height: 228px;
    width: 331px;
    border-top: 2px solid #781d95;
    border-bottom: 2px solid #781d95;
    box-sizing: border-box;
    padding-top: 72px;
  }
  @media screen and (min-width: 1300px) {
    height: 258px;
    width: 331px;
    border-top: 2px solid #781d95;
    border-bottom: 2px solid #781d95;
    box-sizing: border-box;
    padding-top: 72px;
  }
}

.textConfirm {
  margin-left: 18px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.textConfirmAccent {
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #e8a0ff;
}

.containerTextConfirm {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.containerPhoneError {
  > div {
    border: 2px solid red;
  }
}

.phone {
  > div {
    height: 52px;
  }
}
