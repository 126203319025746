.container {
  margin-top: 30px;
  display: flex;
  width: 660px;
}

.containerWrapper {
  width: 330px;
  overflow: hidden;
}

.title {
  text-align: start;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: #e8a0ff;
  margin-top: 60px;
}

.titleAccent {
  text-align: start;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: #e8a0ff;
}
.containerLeft {
  width: 330px;
  height: 440px;
}
.containerWrapperLeft {
  background: rgba(35, 35, 35, 0.4);
  backdrop-filter: blur(40px);
  width: 330px;
  height: 410px;
}

.containerRight {
  margin-top: 30px;
  transform: translateX(0);
  padding: 41px 75px 52px 75px;
  height: 410px;
  width: 330px;
  box-sizing: border-box;
  background: rgba(35, 35, 35, 0.4);
  backdrop-filter: blur(40px);
  transition: all 300ms linear;
}

.containerBackSide {
  transform: translateX(-100%);
}

.smallImg {
  height: 37px;
  width: 35px;
}

.descriptionSmallImg {
  margin-left: 20px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.containerImg {
  text-align: start !important;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.containerIconBack {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: rgba($color: #fff, $alpha: 0.1);
  margin-left: -5px;
  transition: all 300ms;
  &:hover .containerIcon {
    background-color: var(--accent_color);
  }
}
.containerIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  background-color: #fff;
  transition: all 300ms;
  &:hover {
    transition: all 300ms;
  }
}

.containerPlay {
  position: relative;
  align-items: center;
  display: flex;
}

.border {
  position: absolute;
  top: -16px;
  left: -17px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid var(--accent_color);
  opacity: 0;
  -webkit-animation: pulse-2 2.6s cubic-bezier(0.25, 0.9, 0.2, 1) 1s infinite;

  @keyframes pulse-2 {
    0% {
      opacity: 1;
      -webkit-transform: scale(0.5, 0.5);
    }
  }
}
.border1 {
  position: absolute;
  top: -16px;
  left: -17px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid var(--accent_color);
  opacity: 0;
  -webkit-animation: pulse-2 2.6s cubic-bezier(0.25, 0.9, 0.2, 1) 1.3s infinite;

  @keyframes pulse-2 {
    0% {
      opacity: 1;
      -webkit-transform: scale(0.5, 0.5);
    }
  }
}

.icon {
  fill: rgb(45, 44, 44);
  margin-left: 2px;
}

.link {
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  &:focus-visible {
    outline: none;
  }
}

.iconSmall {
  fill: var(--accent_color);
}

.containerSmallIcon {
  width: 37px;
}

.titleTab {
  text-align: center;
  margin-bottom: 26px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;

  color: #e8a0ff;
}

.wrapperButton {
  position: relative;
  display: flex;
  justify-content: center;
  &:hover .iconButtonHover {
    fill: #e8a0ff;
    stroke: #e8a0ff;
    stroke-width: 2px;
    opacity: 1;
  }
  &:active .iconButtonHover {
    fill: #e8a0ff;
    stroke: #b66fcd;
    stroke-width: 2px;
  }
}
.iconButtonHover {
  cursor: pointer;
  margin-top: 30px;
  fill: #e8a0ff;
  stroke: #fff;
  stroke-width: 0.7px;
  fill: rgba(102, 102, 102, 0.3);
  transition: 200ms linear;
}

.link {
  position: absolute;
  top: 20px;
  transition: color 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.button {
  background-color: transparent;
  border: none;
  fill: currentColor;
  color: #fff;
  width: 200px;
  margin: 0 auto;
  margin-left: -10px;
  margin-top: 52px;
}

.img {
  width: 270px;
  height: 270px;
  margin-top: 20px;
}
