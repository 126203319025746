.container {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: relative;
  background-image: url(../../images/turnedPart/section8background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 822px;
  overflow: hidden;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper1 {
  max-width: 1260px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.wrapper2 {
  width: 1260;
}

.containerMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
  position: relative;
  background-image: url(../../images/turnedPart/mobile/back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 628px;
  overflow: hidden;
}
.containerTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  position: relative;
  background-image: url(../../images/turnedPart/mobile/back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 688px;
  overflow: hidden;
}
.containerText {
  margin: 0 auto;
  margin-top: 33px;
  max-width: 754px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 40px;
    left: 165px;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    margin: 0 auto;
    width: 1010px;
    padding: 28px 95px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
    margin-bottom: 34px;
  }
}
.containerTextAccent {
  margin: 0 auto;
  margin-top: 33px;
  max-width: 754px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 40px;
    left: 165px;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    margin: 0 auto;
    margin-top: 80px;
    width: 910px;
    padding: 28px 95px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
  }
}
.title {
  position: absolute;
  top: 35px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  width: 290px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 49px;
    font-weight: 600;
    width: 527px;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    top: 58px;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }
}
.containerWrapper {
  padding-top: 60px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  margin-left: calc(50% - 185px);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-left: calc(50% - 222px);
  }

  @media screen and (min-width: 1300px) {
    padding-top: 60px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    margin-left: calc(50% - 300px);
  }
}

.wrapperText {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.text {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  text-align: center;
}

.iconButtonHover {
  position: absolute;
  fill: rgba(255, 255, 255, 0.3);
  stroke: #fff;
  stroke-width: 1px;
}

.link1 {
  position: absolute;
  z-index: 10;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #ffffff;
  margin-top: 8px;
}
.wrapperButton {
  width: 200px;
  height: 100px;
  bottom: 15px;
  left: 58px;
  position: absolute;
  display: flex;
  justify-content: center;

  &:hover .iconButtonHover {
    fill: #e8a0ff;
    stroke: transparent;
  }
  &:active .iconButtonHover {
    fill: #e8a0ff;
    stroke: #b66fcd;
    stroke-width: 2px;
  }
}

.cardItem {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: flex;
  width: 362px;
  height: 280px;
  border-radius: 10px;
}

.cardImages {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
:global(.swiper-slide-active:hover p) {
  @media screen and (min-width: 1300px) {
    transition: opacity 1s cubic-bezier(0.25, 1, 0.33, 1) 300ms;
    transform: translateY(-100px);
    opacity: 1;
  }
}

:global(.swiper-slide-active:hover > div) {
  @media screen and (min-width: 1300px) {
    transition: all 1s cubic-bezier(0.25, 1, 0.33, 1);
    background-color: rgba(0, 0, 0, 0.7);
  }
}

:global(.swiper-slide-active:hover h2) {
  @media screen and (min-width: 1300px) {
    transition: all 1s cubic-bezier(0.25, 1, 0.33, 1);
    transform: translateY(-100px);
    backdrop-filter: blur(0px);
  }
}

.cardText {
  transition: all 1s cubic-bezier(0.25, 1, 0.33, 1);
  transform: translateY(0);
  position: absolute;
  padding: 15px;
  width: 280px;
  height: 350px;
}
.cardTextActive {
  transform: translateY(0);
  position: absolute;
  padding: 15px;
  width: 280px;
  height: 350px;
  transition: all 1s cubic-bezier(0.25, 1, 0.33, 1);
  background-color: rgba(0, 0, 0, 0.7);
}

.cardTitle {
  width: 100%;
  margin-left: -20px;
  transition: transform 1s cubic-bezier(0.25, 1, 0.33, 1) 300ms,
    backdrop-filter 500ms cubic-bezier(0.25, 1, 0.33, 1);
  margin-top: 110px;
  backdrop-filter: blur(4px);
  text-align: center;
  color: #fbfbfb;
  font-family: TitilliumWeb;
  font-size: 28px;
  line-height: 25px;
  font-weight: 600;
  padding: 20px;
}

.cardTitleActive {
  width: 100%;
  margin-left: -20px;
  margin-top: 90px;
  text-align: center;
  color: #fbfbfb;
  font-family: TitilliumWeb;
  font-size: 28px;
  line-height: 25px;
  font-weight: 600;
  padding: 20px;
  transition: all 1s cubic-bezier(0.25, 1, 0.33, 1);
  transform: translateY(-100px);
  backdrop-filter: blur(0px);
}

.cardDescription {
  transform: translateY(-100px);
  opacity: 0;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: var(--accent_color);
}

.cardDescriptionActive {
  transition: opacity cubic-bezier(0.25, 1, 0.33, 1);
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: var(--accent_color);
  transition: opacity 1s cubic-bezier(0.25, 1, 0.33, 1) 300ms;
  transform: translateY(-100px);
  opacity: 1;
}
.button {
  color: #ffffff;
}

.slide {
  width: 300px;
  height: 300px;
  background-size: contain;
}

.linkId {
  position: absolute;
  top: -50px;
}

.line {
  margin-top: 30px;
  border-top: 3px solid #781d95;
}
