.container {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: relative;
  z-index: 10;
  background-image: url(../../images/materials/back2.jpg);
  width: 100%;
  height: 100vh;
  min-height: 1086px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position: center;
}
.containerMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
  position: relative;
  z-index: 10;
  background-image: url(../../images/materials/mobile/backNew.jpg);
  width: 100%;
  height: 100vh;
  min-height: 830px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.containerTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  position: relative;
  z-index: 10;
  background-image: url(../../images/materials/tablet/backNew.jpg);
  width: 100%;
  height: 90vh;
  min-height: 800px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-position: center;
}

.wrapperContainer {
  padding-top: 60px;
  display: flex;
  justify-content: center;
}

.iconTitle {
  position: absolute;
  top: 0;
  right: 10px;
}
.wrapperAccent {
  width: 331px;

  position: relative;

  @media screen and (max-width: 833px) {
    margin-top: 63px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: calc(50% - 195px);
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 754px;
    margin-top: 24px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media screen and (min-width: 1300px) {
    cursor: pointer;
    padding: 36px 86px;
    width: 503px;
    background: rgba(29, 29, 29, 0.5);
    transition: 300ms;
    &:hover {
      backdrop-filter: blur(12px);
    }
  }
}
.wrapper {
  width: 345px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;

  @media screen and (max-width: 833px) {
    margin-top: 63px;
    margin-left: calc(50% - 340px / 2);
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 754px;
    margin-top: 44px;
    margin-left: calc(50% - 380px);
  }

  @media screen and (min-width: 1300px) {
    padding-top: 90px;
    width: 1880px;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
}
.title {
  position: absolute;
  top: 35px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 45px;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    position: absolute;
    top: 0;
    margin-top: 58px;
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }
}

.text {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 15px;

  @media screen and (min-width: 1300px) {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.textAccent {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #e8a0ff;
}

.item {
  @media screen and (max-width: 833px) {
    width: 311px;
  }

  font-family: Titillium Web;
  font-size: 18px;
  color: #fbfbfb;
  line-height: 20px;
  font-weight: 400;
  margin-left: 11px;
  margin-bottom: 10px;

  @media screen and (min-width: 1300px) {
    font-family: Titillium Web;
    font-size: 20px;
    color: #fbfbfb;
    line-height: 30px;
    font-weight: 400;
    margin-left: 11px;
  }
}

.list {
  border-top: 2px solid #781d95;
  border-bottom: 2px solid #781d95;
  margin: 30px 0 30px 0;
  padding-top: 30px;
  padding-bottom: 30px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin: 22px 0 22px 0;
    padding-top: 22px;
    padding-bottom: 22px;
  }
}

.containerItem {
  display: flex;

  align-items: flex-start;
}
