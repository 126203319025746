.container {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: relative;
  z-index: 100;
  left: 0;
  background-image: url('../../images/orderForm/backgroundmain.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: calc(100vh - 100px);
  height: 1500px;
  overflow: hidden;
  background-color: #2d2e36;
}

.containerTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  background-image: url('../../images/orderForm/backgroundmain.jpg');
  min-height: 100vh;
  height: 1950px;
  position: relative;
  width: 100%;
  background-color: #1e1e1e;
}

.containerMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
  min-height: 100vh;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #1e1e1e;
  overflow: hidden;
}

.wrapper {
  top: 30px;
  position: relative;
  width: calc(100% - 32px);
  padding-bottom: 190px;
  @media screen and (max-width: 833px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 210px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 754px;
    margin-left: calc(50% - 375px);
    padding-bottom: 50px;
    top: 130px;
  }

  @media screen and (min-width: 1300px) {
    margin-left: calc(50% - 620px);
    width: 1240px;
    padding-bottom: 50px;
  }
}

.title {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: fit-content;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 35px;
    line-height: 37px;
    color: var(--main_color);
  }
}

.titleAccent {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 10px;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 50px;
    margin-top: 10px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
    color: var(--main_color);
    margin-bottom: 80px;
    margin-top: 20px;
  }
}

.wrapperContainer {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 0;
  }

  @media screen and (min-width: 1300px) {
    margin-top: 120px;
  }
}

.link {
  position: absolute;
  right: 20px;
  top: 60px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 60px;
    right: 10px;
    width: 50px;
  }
  @media screen and (min-width: 1300px) {
    right: 30px;
    top: 110px;
  }
}

.commonContainerLeft {
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 516px;
    gap: 70px;
    width: 100%;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 800px;
    gap: 70px;
    width: 100%;
  }
}

.commonContainer {
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 754px;
  }
  @media screen and (min-width: 1300px) {
    justify-content: space-between;
    display: flex;
  }
}

.commonContainerRight {
  @media screen and (max-width: 833px) {
    display: flex;
    flex-direction: column;
    width: 331px;
    margin: 0 auto;
    margin-top: 60px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 90px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media screen and (min-width: 1300px) {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 30px;
  }
}

.containerContacts {
  @media screen and (max-width: 833px) {
    border-top: 2px solid #781d95;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 331px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
    padding-bottom: 45px;
  }
  @media screen and (min-width: 1300px) {
    background: rgba(29, 29, 29, 0.7);
    padding: 35px;
  }
}
.containerContacts:not(:last-of-type) {
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    border-top: 2px solid #781d95;
  }
}
.containerContacts:last-of-type {
  @media screen and (max-width: 833px) {
    border-bottom: 2px solid #781d95;
  }
}
.containerContacts:not(:first-of-type) {
  // @media screen and (max-width: 833px) {
  //   padding-top: 50px;
  //   border-bottom: 2px solid #781d95;
  // }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    border-bottom: 2px solid #781d95;
  }
}

.containerContactsLeft {
  width: 100%;
}
.contactsDescription {
  @media screen and (max-width: 833px) {
    justify-content: center;
  }
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.contactsDescriptionAccent {
  @media screen and (max-width: 833px) {
    margin-bottom: 20px;
  }
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #e8a0ff;
  margin-bottom: 25px;
}
.contactsText {
  @media screen and (max-width: 833px) {
    font-size: 16px;
  }
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.contactsTextBottom {
  @media screen and (max-width: 833px) {
    text-align: center;
    justify-content: center;
  }
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    text-align: center;
  }
}
.containerItemAccent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.leftTopSvg {
  position: absolute;
  top: 440px;
  left: 0;
}
.leftTopSvg {
  position: absolute;
  top: 440px;
  left: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

@-webkit-keyframes pulsing2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes pulsing2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.rightTopSvg {
  position: absolute;
  top: 440px;
  right: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.leftBottomSvg {
  position: absolute;
  top: 1500px;
  left: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.rightBottomSvg {
  position: absolute;
  top: 1500px;
  right: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.linkSocial {
  @media screen and (max-width: 833px) {
    text-align: center;
    justify-content: center;
  }

  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: var(--accent_color);
}

.containerItemAccent {
  display: flex;
  align-items: flex-start;
}

.containerMarker {
  width: 10px;
  margin-top: 5px;
}

.contactsTextMail {
  @media screen and (max-width: 833px) {
    font-size: 16px;
  }
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: var(--accent_color);
  text-decoration: underline;
}

.contactsTextBottomMail {
  @media screen and (max-width: 833px) {
    text-align: center;
    justify-content: center;
  }
  text-decoration: underline;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: var(--accent_color);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    text-align: center;
  }
}

.contactsTextTitle {
  @media screen and (max-width: 833px) {
    font-size: 16px;
  }
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #fff;
  margin-right: 5px;
}
