.mainContainer {
  position: relative;
  width: 100%;
  height: 2550px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    position: relative;
    width: 100%;
    height: 2350px;
  }

  @media screen and (min-width: 1300px) {
    position: relative;
    width: 100%;
    height: 2360px;
  }
}
.mainContainerDe {
  position: relative;
  width: 100%;
  height: 2600px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    position: relative;
    width: 100%;
    height: 2400px;
  }

  @media screen and (min-width: 1300px) {
    position: relative;
    width: 100%;
    height: 2360px;
  }
}

.container {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  background-attachment: fixed;
  position: sticky;
  min-height: 1300px;
  height: 100vh;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../images/machinery/img.jpg');
  // -webkit-mask-image: linear-gradient(180deg, #000 88%, transparent 100%);
}
.containerMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
  height: 100vh;
  background-attachment: fixed;
  position: sticky;
  min-height: 1300px;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../images/machinery/mobile/background.jpg');
  // -webkit-mask-image: linear-gradient(180deg, #000 88%, transparent 100%);
}
.containerTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  background-attachment: fixed;
  position: sticky;
  height: 100vh;
  min-height: 1300px;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../images/machinery/tablet/Vector.jpg');
  // -webkit-mask-image: linear-gradient(180deg, #000 88%, transparent 100%);
}

.containerWrapper {
  margin-top: 60px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 833px) {
    margin-left: calc(50% - 185px);
  }
  margin-left: calc(50% - 230px);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 56px;
    margin-left: calc(50% - 215px);
  }

  @media screen and (min-width: 1300px) {
    margin-top: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 56px;
    margin-left: calc(50% - 300px);
  }
}

.containerWrapperDe {
  margin-top: 60px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 833px) {
    margin-left: calc(50% - 185px);
    // margin-top: 150px;
  }
  margin-left: calc(50% - 230px);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 56px;
    margin-left: calc(50% - 215px);
  }

  @media screen and (min-width: 1300px) {
    margin-top: 120px;
    display: flex;
    align-items: center;
    margin-bottom: 56px;
    margin-left: calc(50% - 300px);
  }
}

.wrapper {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: absolute;
  top: 650px;
  right: calc(50vw - 50%);
  z-index: 5;
  left: 0;
  overflow: hidden;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 710px;
  }
  max-width: 1250px;
  margin: 0 auto;
  @media screen and (min-width: 1300px) {
    top: 1075px;
  }
}
.wrapperMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
  position: absolute;
  top: 650px;
  right: calc(50vw - 50%);
  z-index: 5;
  left: 0;
  overflow: hidden;

  margin: 0 auto;
}
.wrapperTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  position: absolute;
  top: 710px;
  right: calc(50vw - 50%);
  z-index: 5;
  left: 0;
  overflow: hidden;

  width: 100%;
  margin: 0 auto;
}
.containerTabletWrapper {
  display: flex;
}
.wrapperBlur {
  background: rgba(29, 29, 29, 0.2);
  // backdrop-filter: blur(20px);

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    padding: 20px;
    width: 715px;
    margin-left: calc(50% - 360px);
    background: transparent;
    // background: rgba(29, 29, 29, 0.2);
    // backdrop-filter: blur(20px);
    // display: flex;
  }

  @media screen and (min-width: 1300px) {
    padding: 73px 86px 0 86px;
    display: flex;
    backdrop-filter: blur(20px);
    height: 890px;
  }
}
.title {
  font-family: TitilliumWeb;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
  }
}
.descriptionBottom {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
}
.description {
  @media screen and (max-width: 833px) {
    margin-top: 54px;
  }
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
  }
}
.descriptionLine {
  @media screen and (max-width: 833px) {
    margin-top: 53px;
  }
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 70px;
  }
  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
  }
}

.descriptionBottom {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
}
.list {
  color: #fbfbfb;
  margin: 10px 0 0 0;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin: 10px 0 20px 0;
  }

  @media screen and (min-width: 1300px) {
    margin: 10px 0 20px 0;
  }
}
.containerItemAccent {
  display: flex;

  align-items: flex-start;
}

.containerItemAccentModal {
  display: flex;
  align-items: flex-start;
  &:last-of-type {
    margin-top: 10px;
  }
}

.containerItem {
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  margin-left: 11px;
  font-family: Titillium Web;

  color: #fbfbfb;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    line-height: 30px;

    font-size: 18px;
    font-weight: 400;
  }
}

.itemAccent {
  display: flex;
  margin-left: 11px;
  font-family: Titillium Web;
  line-height: 20px;
  color: #fbfbfb;
  font-size: 16px;
  font-weight: 400;
}

.containerLeft {
  position: relative;
  @media screen and (max-width: 833px) {
    position: relative;
    margin-left: calc(50% - 165px);
    width: 332px;
    box-sizing: border-box;
    padding-bottom: 5;
  }

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 399px;
    padding: 0;
  }

  @media screen and (min-width: 1300px) {
    width: 520px;
    padding-right: 30px;
  }
}

.iconTitle {
  position: absolute;
  right: 15px;
  top: -10px;
}

.iconTitleDescription {
  position: absolute;
  right: -300px;
  top: -10px;
}

.iconTitleAccent {
  position: absolute;
  right: 70px;
  top: -10px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    right: 30px;
    top: 55px;
  }
}

.containerRight {
  position: relative;
  @media screen and (max-width: 833px) {
    margin-left: calc(50% - 165px);
    width: 332px;

    padding-top: 0;
    padding-top: 5;
    box-sizing: border-box;
  }

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 330px;
    padding: 0 20px;
  }

  @media screen and (min-width: 1300px) {
    width: 483px;
    padding: 0 20px;
  }
}

.button {
  border: none;
  min-width: 110px;
  cursor: pointer;
  background-color: transparent;
  height: 33px;
  width: 96px;
  border: solid 2px transparent;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  color: #ffffff;
  // background: rgba(255, 255, 255, 0.25);
  &:hover {
    background-color: var(--accent_color);
    border: transparent;
    color: #1d1d1d;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 143px;
  }
  @media screen and (min-width: 1300px) {
    min-width: 96px;
    width: 96px;
    box-sizing: border-box;
  }
}
.button0 {
  background-color: rgba(255, 255, 255, 0.45);
}

.button1 {
  background-color: rgba(255, 255, 255, 0.35);
}

.button2 {
  background-color: rgba(255, 255, 255, 0.25);
}

.button3 {
  background-color: rgba(255, 255, 255, 0.15);
}

.button4 {
  background-color: rgba(255, 255, 255, 0.05);
}

.buttonActive {
  min-width: 110px;
  cursor: pointer;
  border: solid 2px var(--accent_color);
  background-color: var(--accent_color);
  height: 33px;
  width: 96px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  color: #1d1d1d;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 143px;
  }
  @media screen and (min-width: 1300px) {
    min-width: 96px;
    width: 96px;
    box-sizing: border-box;
  }
}

.listButton {
  display: flex;
  padding: 0;
  margin: 0;
}

.routes {
  margin-top: 30px;
  // @media screen and (min-width: 834px) and (max-width: 1299px) {
  // }
  @media screen and (min-width: 1300px) {
    margin-top: 104px;
  }
}

.line {
  border-top: 2px solid #781d95;
  margin-top: 32px;
  margin-bottom: 32px;
}
.lineNew {
  border-top: 2px solid #781d95;
  margin-top: 32px;
  margin-bottom: 32px;
}
.lineNewEn {
  border-top: 2px solid #781d95;
  margin-top: 32px;
  margin-bottom: 32px;
}

.lineTablet1 {
  border-top: 2px solid #781d95;
  margin-top: 32px;
  margin-bottom: 70px;
}

.lineTablet {
  border-top: 2px solid #781d95;
  margin-top: 55px;
  margin-bottom: 32px;
}

.lineTabletEn {
  border-top: 2px solid #781d95;
  margin-top: 32px;
  margin-bottom: 32px;
}

.lineAccent {
  border-top: 2px solid #781d95;
  margin-top: 32px;
  margin-bottom: 5px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  @media screen and (min-width: 1300px) {
    margin-top: 104px;
  }
}
.lineAccentEn {
  border-top: 2px solid #781d95;
  margin-top: 32px;
  margin-bottom: 5px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  @media screen and (min-width: 1300px) {
    margin-top: 81px;
  }
}

.descriptionAccent {
  @media screen and (max-width: 833px) {
    width: 320px;
    margin-top: 28px;
  }

  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  color: #e8a0ff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 75px;
  }
}
.descriptionAccentEn {
  font-family: 'Titillium Web';
  font-style: normal;
  color: #e8a0ff;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 99px;
}

.descriptionAccentText {
  margin-top: 25px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #e8a0ff;
  @media screen and (min-width: 1300px) {
    margin-top: 50px;
  }
}

.containerLeftAccent {
  position: relative;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 310px;
  }

  @media screen and (min-width: 1300px) {
    width: 315px;
    margin-top: 90px;
  }
}

.containerMarker {
  width: 10px;
}

.containerTablet1 {
  position: relative;
  background-color: transparent;
}

.leftArrowWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 44px;
  &:hover .arrow {
    fill: var(--accent_color);
    transition: fill 300ms;
  }
}

.rightArrowWrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  width: 44px;
  &:hover .arrow {
    fill: var(--accent_color);
    transition: fill 300ms;
  }
}

.arrow {
  cursor: pointer;
  margin-top: 395px;
  fill: var(--accent_color);
  transition: fill 300ms;
}

.linkMobile {
  position: absolute;
  top: -50px;
}

.link {
  position: absolute;
  top: 0;
}
