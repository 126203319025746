.wrapper {
  position: fixed;
  bottom: 45px;
  right: 15px;
  cursor: pointer;
  z-index: 15;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    right: 30px;
  }

  @media screen and (min-width: 1300px) {
    right: 30px;
  }
}

.icon {
  fill: var(--accent_color);
  opacity: 0;
  transition-delay: 2s;
  width: 26px;
  height: 26px;
  -webkit-animation: fadebottom 2s ease-in-out 2s infinite;

  @keyframes fadebottom {
    0% {
      opacity: 1;
      -webkit-transform: translate(0, 35px);
    }
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 44px;
    height: 44px;
  }

  @media screen and (min-width: 1300px) {
    width: 44px;
    height: 44px;
  }
}
