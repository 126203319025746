.wrapper .control-dots {
  display: none;
}

.container {
  @media screen and (max-width: 833px) {
    height: 650px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    height: 800px;
  }
  background-color: rgba(0, 0, 0);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 1000px;
}

.containerTitleAccent {
  margin-top: 200px;
}
.containerTitleWrapper {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
}
.containerTitle {
  text-align: start;
  margin-left: 130px;
}
.video {
  display: block;
  min-width: 100%;
  min-height: 100%;
  // cursor: grab;
}

.containerImg {
  @media screen and (max-width: 833px) {
    background-image: url('../../images/hero/mobile/images.jpg');
  }
  @media screen and (min-width: 833px) and (max-width: 1299px) {
    background-image: url('../../images/hero/tablet/images.jpg');
  }
  @media screen and (min-width: 1300px) {
    background-image: url('../../images/hero/images.jpg');
  }
  width: 100vw;
  height: 100%;

  cursor: grab;
  background-repeat: no-repeat;
  background-size: cover;
}
.videoWrapper {
  height: 100%;
  width: 100%;
  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.videoWrapperMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
}
.videoWrapperTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
}
.containerImgMobile {
  position: relative;
  background-image: url(../../images/hero/mobile/background.jpg);
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  min-height: 650px;
}
.containerImgTablet {
  position: relative;
  background-image: url(../../images/hero/tablet/background.jpg);
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  min-height: 800px;
}

.containerText {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: absolute;
  width: 1240px;
  top: 250px;
  left: calc(50% - 1240px / 2);
  text-align: left;
}
.containerTextMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }

  position: absolute;
  width: 360px;
  top: 176px;
  left: calc(50% - 150px);
  text-align: left;
}
.containerTextTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  position: absolute;
  width: 790px;
  top: 194px;
  left: calc(50% - 340px);
  text-align: left;
}

.containerTitle {
  margin-bottom: 72px;
}

.title {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
  transition: opacity 600ms cubic-bezier(0.25, 1, 0.33, 1);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 49px;
    line-height: 50px;
  }
}

.title2 {
  cursor: pointer;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0.738261px 1.47652px 2.95304px rgba(0, 0, 0, 0.15);
  transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 33.7145px;
    line-height: 31px;
    margin-bottom: 30px;
  }
}

.textHide {
  opacity: 0;

  transition: opacity 300ms cubic-bezier(0.25, 1, 0.33, 1);
}

.animateOpacity {
  transition: opacity 600ms cubic-bezier(0.25, 1, 0.33, 1);
}

.text {
  font-family: TitilliumWeb;
  font-size: 24px;
  line-height: 38px;
  font-weight: 300;
  color: #fbfbfb;
  font-weight: 300;

  transition: opacity 300ms cubic-bezier(0.25, 1, 0.33, 1);
}
.textAds {
  font-family: TitilliumWeb;
  font-size: 32px;
  line-height: 38px;
  font-weight: 300;
  color: var(--accent_color);
  font-weight: 300;

  transition: opacity 300ms cubic-bezier(0.25, 1, 0.33, 1);
}

.link {
  display: block;
  width: fit-content;
  border: solid 1px var(--accent_color);
  color: #fbfbfb;
  border-radius: 5px;
  padding: 13px 24px;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  background-color: transparent;
  transition: background-color 0.45s cubic-bezier(0.25, 1, 0.33, 1);

  &:hover {
    background-color: var(--accent_color);
    transition: background-color 0.45s cubic-bezier(0.25, 1, 0.33, 1);
    border: solid 1px transparent;
  }
}

.wrapperText {
  margin-bottom: 40px;
  margin-top: 15px;
}

.arrow {
  width: 44px;
  height: 26px;
  margin-top: 50vh;
  fill: var(--accent_color);
  transition: fill 300ms;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 44px;
    height: 44px;
  }

  @media screen and (min-width: 1300px) {
    width: 44px;
    height: 44px;
  }
}

.leftArrowWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 44px;
  &:hover .arrow {
    fill: var(--accent_color);
    transition: fill 300ms;
  }
}

.rightArrowWrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  width: 44px;
  &:hover .arrow {
    fill: var(--accent_color);
    transition: fill 300ms;
  }
}

.control-dots {
  display: none !important;
}

.hide {
  opacity: 0;
  transform: translateY(40px);
}

.visibleTitle {
  opacity: 1;
  transform: translateY(0px);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 500ms;
}

.visibleText {
  opacity: 1;
  transform: translateY(0px);
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1) 500ms;
}

.visibleButton {
  opacity: 1;
  transform: translateY(0px);
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 500ms,
    opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 500ms;
}

.arrowDown {
  position: absolute;
  bottom: 10px;
  right: calc(100% / 2);
}

.arrowDownSvg {
  @media screen and (max-width: 1299px) {
    display: none;
  }
  fill: var(--accent_color);
  opacity: 0;
  transition-delay: 2s;
  -webkit-animation: fadebottom 2s ease-in-out 2s infinite;

  @keyframes fadebottom {
    0% {
      opacity: 1;
      -webkit-transform: translate(0, -35px);
    }
  }
}

.containerTitleGap {
  margin-left: -10px;
  @media screen and (min-width: 1300px) {
    margin-bottom: 75px;
  }
}

.description {
  opacity: 0;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media screen and (min-width: 1300px) {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 36px;
  }
}

.descriptionVisible {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media screen and (min-width: 1300px) {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 36px;
  }
}

.descriptionAccent {
  opacity: 0;
  width: 244px;
  margin-top: 25px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-shadow: 1.34858px 2.69716px 5.39432px rgba(0, 0, 0, 0.15);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-size: 14px;
    line-height: 20px;
    width: 307px;
    margin-top: 26px;
  }
  @media screen and (min-width: 1300px) {
    font-size: 18px;
    line-height: 24px;
    width: 394px;
    margin-top: 43px;
  }
}

.descriptionVisibleAccent {
  width: 244px;
  margin-top: 25px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-shadow: 1.34858px 2.69716px 5.39432px rgba(0, 0, 0, 0.15);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-size: 14px;
    line-height: 20px;
    width: 307px;
    margin-top: 26px;
  }
  @media screen and (min-width: 1300px) {
    font-size: 18px;
    line-height: 24px;
    width: 394px;
    margin-top: 43px;
  }
}
