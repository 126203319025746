.container {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: relative;
  z-index: 100;
  left: 0;
  background-image: url('../../images/orderForm/backgroundmain.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  height: 700px;
  overflow: hidden;
  background-color: #2d2e36;
}

.wrapper {
  // top: 30px;
  position: relative;
  width: 100%;
  padding-bottom: 190px;
  width: 335px;
  margin-left: calc(50% - 178px);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 120px;
    width: 754px;
    margin-left: calc(50% - 375px);
    padding-bottom: 50px;
  }

  @media screen and (min-width: 1300px) {
    top: 30px;
    margin-left: calc(50% - 620px);
    width: 1240px;
    padding-bottom: 50px;
  }
}

.title {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 217px;
  width: fit-content;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: fit-content;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 35px;
    line-height: 37px;

    color: var(--main_color);
    width: fit-content;
  }
}

.wrapperContainer {
  @media screen and (max-width: 833px) {
    margin-bottom: 50px;
  }
  display: flex;
  justify-content: center;
  margin-bottom: 90px;
  margin-top: 120px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-bottom: 60px;
    margin-top: 0px;
  }
  @media screen and (min-width: 1300px) {
    margin-bottom: 90px;
    margin-top: 120px;
  }
}

.item {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  border-radius: 25px;
  display: flex;
  align-items: center;
  border: solid 2px transparent;
  justify-content: space-between;
  transition: 200ms;

  &:hover svg {
    stroke: #e8a0ff;
  }
}

.backgroundItem {
  position: absolute;
  display: flex;
  gap: 10px;
  left: 36px;
  top: 25px;

  @media screen and (max-width: 833px) {
    flex-direction: column;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    display: flex;
    flex-direction: column;
    gap: 14px;
    justify-content: center;
    align-items: center;
    width: 585px;
    left: 70px;
  }

  @media screen and (min-width: 1300px) {
    display: flex;
    align-items: center;
    left: 70px;
    top: 25px;
    width: 1090px;
    justify-content: space-between;
  }
}

.descriptionPosition {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #fff;
  @media screen and (max-width: 833px) {
    text-align: center;
    justify-content: center;
  }

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: fit-content;
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
  }

  @media screen and (min-width: 1300px) {
    font-size: 18px;
    line-height: 20px;
    color: var(--main_color);
    width: fit-content;
  }
}

.descriptionPositionAccent {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #fff;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: fit-content;
    font-size: 18px;
    line-height: 27px;
  }

  @media screen and (min-width: 1300px) {
    font-size: 18px;
    line-height: 20px;

    color: var(--main_color);
    width: fit-content;
  }
}
.listPosition {
  display: flex;
  flex-direction: column;
  gap: 160px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    gap: 150px;
  }
  @media screen and (min-width: 1300px) {
    gap: 100px;
  }
}

.containerTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  background-image: url('../../images/orderForm/backgroundmain.jpg');
  min-height: 100vh;
  height: 1000px;
  position: relative;
  width: 100%;
  background-color: #1e1e1e;
}

.containerMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
  min-height: 100vh;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #1e1e1e;
  overflow: hidden;
}

.flexContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 279px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 466px;
  }
  @media screen and (min-width: 1300px) {
    width: 550px;
  }
}

.leftTopSvg {
  position: absolute;
  top: 440px;
  left: 0;
}
.leftTopSvg {
  position: absolute;
  top: 440px;
  left: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

@-webkit-keyframes pulsing2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes pulsing2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.rightTopSvg {
  position: absolute;
  top: 440px;
  right: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.leftBottomSvg {
  position: absolute;
  top: 1500px;
  left: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.rightBottomSvg {
  position: absolute;
  top: 1500px;
  right: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}
