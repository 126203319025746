.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;
  background-color: rgba(12, 12, 13, 0.7);
  backdrop-filter: blur(6px);
}

.wrapperAccent {
  position: absolute;
  top: 31802px;
  left: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;
  background-color: rgba(12, 12, 13, 0.7);
  backdrop-filter: blur(6px);
  @media screen and (min-width: 833px) and (max-width: 1299px) {
    top: 17570px;
  }
  @media screen and (min-width: 1300px) {
    top: 13364px;
  }
}
.wrapperAccentDe {
  position: absolute;
  top: 38810px;
  left: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;
  background-color: rgba(12, 12, 13, 0.7);
  backdrop-filter: blur(6px);
  @media screen and (min-width: 833px) and (max-width: 1299px) {
    top: 19988px;
  }
  @media screen and (min-width: 1300px) {
    top: 14939px;
  }
}

.footer {
  display: block;
  width: 100%;
}

.socialWrapper {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}

.linkList {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #781d95;
  transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: var(--accent_color);
    box-shadow: drop-shadow(0px 0px 4px rgba(120, 29, 149, 0.8));
    & .icon {
      color: var(--main_color);
      transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
    }
  }
}
.icon {
  margin-top: 3px;
  fill: currentColor;
  color: var(--main_color);
  transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
}

.text {
  font-family: 'Roboto', sans-serif;
  font-size: var(--font);
  font-weight: 400;
  text-align: center;
  color: var(--main_color);
}

.widget {
  font-size: var(--font);
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.textLink {
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: var(--font);
  font-weight: 400;
  text-align: center;
  color: var(--accent_color);
  text-decoration: underline;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(29, 29, 29, 0.2);
  backdrop-filter: blur(24px);
}

.closeIcon {
  cursor: pointer;
  position: fixed;
  top: 120px;
  right: 30px;
  z-index: 10000;
  fill: #111111;
  @media screen and (max-width: 833px) {
    top: 70px;
    right: 10px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 70px;
    right: 15px;
  }
}

.backdropWrapper {
  margin-bottom: 50px;
  @media screen and (max-width: 833px) {
    width: calc(100vw - 40px);

    padding-right: 20px;
    padding-left: 20px;
  }
  overflow-y: auto;

  height: 100%;

  @media screen and (min-width: 834px) {
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
  }
  @media screen and (min-width: 1300px) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.backdropWrapper::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
.backdropWrapper::-webkit-scrollbar-thumb {
  background-color: #3b3b3b;
  border-radius: 5px;
}

.imgTextAccent {
  justify-content: center;
  margin-bottom: 20px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 33px;
    line-height: 22px;
  }
}

.contactText {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-right: 10px;

  @media screen and (min-width: 1300px) {
    font-family: Titillium Web;
    font-size: 18px;
    font-weight: 400;
    color: #e6e8ec;
  }
}

.mailLink {
  display: block;
  font-family: 'Titillium Web';
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
}
.line1 {
  @media screen and (max-width: 833px) {
    width: 331px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  border-top: 2px solid #781d95;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @media screen and (min-width: 1300px) {
    margin-top: 18px;
    margin-bottom: 18px;
  }
}

.contactDetails {
  z-index: 500;
  margin-top: 200px;

  @media screen and (max-width: 833px) {
    left: calc(50% - 166px);
    width: 100%;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 650px;
    left: calc(50% - 650px / 2);
    justify-content: space-between;
  }

  @media screen and (min-width: 1300px) {
    z-index: 500;
    right: calc(50% - 500px);
    padding: 20px 20px 20px 20px;
    width: 850px;
  }
}

.contactDetailsDe {
  z-index: 500;
  width: 331px;
  margin-top: 200px;

  @media screen and (max-width: 833px) {
    left: calc(50% - 166px);
    width: 100%;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 758px;
    left: calc(50% - 758px / 2);
    justify-content: space-between;
  }

  @media screen and (min-width: 1300px) {
    z-index: 500;
    right: calc(50% - 500px);
    padding: 20px 20px 20px 20px;
    width: 850px;
  }
}

.containerContactText {
  display: flex;
  margin-top: 20px;
}

.wrapperModalText {
  display: flex;
}

.contactCentral,
.contactRight {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
