.container {
  position: relative;
  @media screen and (max-width: 1300px) {
    display: none;
  }

  width: 100%;
  height: 1080px;
  background-image: url('../../images/hero/images.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.containerMobile {
  position: relative;
  @media screen and (min-width: 834px) {
    display: none;
  }

  width: 100%;
  height: 651px;
  background-image: url('../../images/hero/mobile/images.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  min-height: fit-content;
}

.wrapperContainer {
  padding-top: 60px;
  display: flex;
  justify-content: center;
}

.containerTablet {
  position: relative;
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }

  width: 100%;

  height: 707px;
  background-image: url('../../images/hero/tablet/images.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  min-height: fit-content;
}

.containerTitle {
  @media screen and (max-width: 833px) {
    width: 400px;
    left: calc(50% - 165px);
    top: 250px;
  }
  position: absolute;
  width: 1240px;
  top: 360px;
  left: calc(50% - 620px);
  text-align: left;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 754px;
    left: calc(50% - 754px / 2);
    top: 290px;
  }
}

.title {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 10px;
  margin-left: 10px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 23px;
    line-height: 26px;
    margin: 0;
  }
  @media screen and (max-width: 833px) {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    margin: 0;
  }
}

.containerDescription {
  margin: 0 auto;
  width: 1240px;
  margin-top: 317px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 754px;
    margin: 0 auto;
    margin-top: 215px;
  }
  @media screen and (max-width: 833px) {
    width: 400px;
    margin: 0 auto;
    margin-top: 175px;
  }
}

.description {
  margin-left: 20px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
  }
  @media screen and (max-width: 833px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    width: 200px;
  }
}

.descriptionAccent {
  margin-left: 20px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 31px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 12px;
  }
  @media screen and (max-width: 833px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
  }
}
