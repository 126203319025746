.container {
  position: relative;
  z-index: 100;
  left: 0;
  background-image: url('../../images/orderForm/backgroundmain.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: calc(100vh - 100px);
  height: 3010px;
  overflow: hidden;
  background-color: #2d2e36;
}
.gif {
  width: 86px;
  height: 60px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 501;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.8);
}
.wrapper {
  position: absolute;
  top: 30px;
  z-index: 2;
  left: calc(50% - 1240px / 2);
  width: 1240px;
}

.wrapperContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 66px;
  margin-top: 100px;
}

.title {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 37px;
  text-transform: uppercase;
  color: #ffffff;
}

.secondTitle {
  font-family: 'Titillium Web';
  font-weight: 700;
  font-size: 22px;
  line-height: 109%;
  text-transform: uppercase;
  color: var(--main_color);
  text-shadow: 2px 2px 3px rgba(29, 29, 29, 0.7);
}
.titleContainer {
  align-items: center;
  text-align: center;
  margin-bottom: 66px;
}

.containerFile {
  display: flex;
  justify-content: space-between;
  margin-top: 66px;
  margin-bottom: 66px;
}

.text {
  margin-left: 20px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 122%;
  color: var(--main_color);
}

.upload {
  margin-top: 20px;
  margin-bottom: 54px;
  width: 486px;
  height: 148px;
  padding: 8px;
  background: rgba(12, 12, 13, 0.7);
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}

.innerText {
  border: 1px dashed rgba(255, 255, 255, 0.4);
  display: flex;
  padding: 29px 54px;
  align-items: center;
}

.iconUpload {
  fill: var(--main_color);
}

.textUpload {
  margin-left: 34px;
  width: 230px;
}

.textAccent {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.4);
}

.containerQuantity {
  z-index: 2;
  margin-bottom: 45px;
}

.containerMaterial {
  position: relative;
  margin-bottom: 40px;
  margin-top: 40px;
}
.containerAccentText {
  width: 508px;
  margin-left: 20px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--main_color);
  margin-bottom: 20px;
}

.wrapperAccentText {
  width: 510px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--main_color);
  margin-top: 20px;
}
.wrapperAccentTextBottom {
  width: 510px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--main_color);
}
.containerAccentTextInput {
  margin-left: 20px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--main_color);
  margin-bottom: 20px;
}

.containerAccentTextDelivery {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #fbfbfb;
  margin-bottom: 12px;
}

.containerAccentTextPink {
  color: var(--accent_color);
}

.inputText,
.inputTextDelivery {
  border: solid 2px transparent;
  width: 486px;
  height: 52px;
  padding-left: 20px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 13px;
  line-height: 138%;
  color: rgba(255, 255, 255, 0.4);
  background: rgba(12, 12, 13, 0.7);
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  outline: none;
}

.inputTextCountry {
  position: relative;
  width: 456px;
  height: 52px;
  padding-left: 50px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 13px;
  line-height: 138%;
  color: rgba(255, 255, 255, 0.4);
  background: rgba(12, 12, 13, 0.7);
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  border: none;
  outline: none;
}

.wrapperInputText {
  position: relative;
  z-index: 10;
}

.inputTextAccent {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #fbfbfb;

  background: rgba(230, 232, 236, 0.1);
  border: none;
  outline: none;
  padding: 14px 18px;
}

.titleTextAria {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #fbfbfb;
}

.textArea {
  width: 100%;
  max-width: 1200px;
  height: 148px;
  margin-top: 20px;
  margin-bottom: 66px;
  padding: 16px 18px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.4);
  background: rgba(12, 12, 13, 0.7);
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  outline: none;
  border: 2px solid rgba(12, 12, 13, 0.7);
}

.inputTextDelivery {
  border: 2px solid rgba(12, 12, 13, 0.7);
}

.textArea:focus,
.inputTextDelivery:focus,
.inputText:focus {
  border: 2px solid #e8a0ff;
}

.contaionerDeliveryTerms {
  display: flex;
  justify-content: space-between;
  margin-top: 66px;
  margin-bottom: 66px;
}
.rightContainer {
  width: 510px;
}
.radioButton {
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 122%;
  color: var(--main_color);
}

.containerRadioButtonTop {
  margin-bottom: 8px;
}

.textRadioButton {
  margin-left: 24px;
}

.inputRadioButton {
  width: 0;
  height: 0;
  background-color: var(--accent_color);
}

.containerContactsDetails {
  margin-top: 150px;
}

.containerInputWrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
}
.wrapperSmallContainer {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
}

.smallContainerRight {
  margin-left: 85px;
}

.containerEmail {
  margin-top: 40px;
  margin-bottom: 30px;
}

.containerEmailError {
  > div {
    border: 2px solid red;
  }
}

.wrapperSmallContainerAddress {
  margin-top: 40px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
}

.containerBottom {
  position: relative;
  margin-top: 20px;
}

.inputText,
.inputTextDelivery::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 138%;
  color: rgba(255, 255, 255, 0.4);
}

.containerButton {
  margin-top: 40px;
}
.buttonDisabled {
  text-transform: uppercase;
  font-family: 'Roboto';
  width: 230px;
  height: 54px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #fbfbfb;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 15px 20px;
  cursor: pointer;
  border: solid 1px #fff;
}
.button {
  text-transform: uppercase;
  font-family: 'Roboto';
  width: 230px;
  height: 54px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #fbfbfb;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 15px 20px;
  cursor: pointer;
  border: solid 1px #fff;
  &:hover {
    background-color: #e8a0ff;
    color: #1d1d1d;
    border: none;
  }
  &:active {
    background-color: #e8a0ff;
    color: #1d1d1d;
    border: solid 2px #b66fcd;
  }
}

.uploadFileInput {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.iconUploadDone {
  margin-bottom: 80px;
}

.containerSubmit {
  min-height: calc(100vh - 494px);
  text-align: center;
  padding-top: 145px;
  padding-bottom: 100px;
}

.submitText {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 147.69%;
  text-align: center;
  color: #fbfbfb;
}

.wrapperSubmit {
  margin-bottom: 130px;
}

.logoWrapper {
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #fbfbfb;
  background: var(--accent_color);
  border-radius: 5px;
  padding: 15px 20px;
}
.iconArrowUp {
  padding: 10px;
  position: absolute;
  z-index: 100;
  left: 466px;
  top: 5px;
  fill: var(--main_color);
  transform: rotate(180deg);
}
.selectQuantity {
  height: 36px;
  cursor: pointer;
  padding-left: 20px;
  font-family: 'Roboto';
  padding-top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: var(--main_color);
  border: none;
  outline: none;

  &:nth-child(odd) {
    background: rgba(12, 12, 13, 0.6);
    backdrop-filter: blur(5px);
  }

  &:nth-child(even) {
    background: rgba(12, 12, 13, 0.5);
    backdrop-filter: blur(5px);
  }

  &:hover {
    background: var(--accent_color);
    color: #1d1d1d;
  }
}

.containerOption {
  width: 100%;
  transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
  position: absolute;
}
.select {
  height: 36px;
  cursor: pointer;
  padding-left: 20px;
  font-family: 'Roboto';
  padding-top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: var(--main_color);
  border: none;
  outline: none;
  &:nth-child(odd) {
    background: rgba(12, 12, 13, 0.6);
    backdrop-filter: blur(5px);
  }

  &:nth-child(even) {
    background: rgba(12, 12, 13, 0.5);
    backdrop-filter: blur(5px);
  }

  &:hover {
    background: var(--accent_color);
    color: #1d1d1d;
  }
}

:global {
  .PhoneInputCountrySelect,
  .PhoneInputCountrySelectArrow {
    display: none;
  }

  .PhoneInputInput {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #fbfbfb;
    height: 22px;
    width: 894px;
    background: rgba(230, 232, 236, 0);
    border: none;
    outline: none;
    padding: 14px 18px;

    &::placeholder {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 138%;

      color: rgba(255, 255, 255, 0.4);
    }
  }

  .PhoneInput--focus {
    border: 2px solid #e8a0ff;
  }
}

.countryFlag {
  position: absolute;
  top: 53px;
  display: flex;
  width: 27px;

  :global {
    .PhoneInputInput {
      display: none;
    }
  }
}

.flag {
  position: absolute;
  top: -38px;
  left: 18px;
}
.containerOptionMaterials {
  background: rgba(12, 12, 13, 0.6);
  width: 505px;
}
.containerOptionMaterialsCommon {
  transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
  position: absolute;
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 505px;
}
.containerOptionMaterialsCommon::-webkit-scrollbar-thumb {
  background-color: var(--accent_color);
  border-radius: 5px;
}
.containerOptionMaterialsCommon::-webkit-scrollbar {
  width: 5px;
}

.selectMaterialsCategory {
  position: relative;
  padding-top: 20px;
  cursor: pointer;
  min-height: 32px;
  padding-left: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: var(--main_color);
  border: none;
  outline: none;

  &:nth-child(odd) {
    background: rgba(12, 12, 13, 0.2);
    backdrop-filter: blur(5px);
  }

  &:nth-child(even) {
    background: rgba(12, 12, 13, 0.3);
    backdrop-filter: blur(5px);
  }

  &:hover {
    background: var(--accent_color);
    color: #1d1d1d;
  }
}

.selectMaterialsCategoryActive {
  padding: 0;
  &:nth-child(odd) {
    background: rgba(212, 212, 219, 0.3);
    backdrop-filter: blur(5px);
  }

  &:nth-child(even) {
    background: rgba(212, 212, 219, 0.2);
    backdrop-filter: blur(5px);
  }
  &:hover {
    background: rgba(212, 212, 219, 0.3);
    color: #1d1d1d;
  }
}

.selectMaterials {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 20px;
  height: 52px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);

  border: none;
  outline: none;

  &:nth-child(odd) {
    background: rgba(12, 12, 13, 0.2);
    backdrop-filter: blur(5px);
  }

  &:nth-child(even) {
    background: rgba(12, 12, 13, 0.3);
    backdrop-filter: blur(5px);
  }

  &:hover {
    background: var(--accent_color);
    color: #1d1d1d;
  }
}
.selectMaterialsActive {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 20px;
  height: 52px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);

  border: none;
  outline: none;

  &:nth-child(odd) {
    background: rgba(12, 12, 13, 0.2);
    backdrop-filter: blur(5px);
  }

  &:nth-child(even) {
    background: rgba(12, 12, 13, 0.3);
    backdrop-filter: blur(5px);
  }

  &:hover {
    background: var(--accent_color);
    color: #1d1d1d;
  }
}

.iconArrow {
  position: absolute;
  left: 466px;
  top: 5px;
  fill: #e6e8ec;
  padding: 10px;
  z-index: 1;
}

.subTitle {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 73%;
  text-align: center;
  text-transform: uppercase;

  color: var(--main_color);
}

.countrySelect {
  max-height: 227px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 505px;
}

.countrySelect::-webkit-scrollbar-thumb {
  background-color: var(--accent_color);
  border-radius: 5px;
}
.countrySelect::-webkit-scrollbar {
  width: 5px;
  background-color: #000;
}
.wrapperInput {
  position: relative;
}

.iconArrowQuantity {
  position: absolute;
  padding: 10px;
  left: 468px;
  top: 12px;
  fill: var(--main_color);
}

.iconArrowQuantityUp {
  position: absolute;
  padding: 10px;
  left: 468px;
  top: 12px;
  fill: var(--accent_color);
  transform: rotate(180deg);
}

.countryArrow {
  cursor: pointer;
  left: 470px;
  top: -43px;
}

.countryDelete {
  top: -47px;
  left: 466px;
  cursor: pointer;
}

.leftTopSvg {
  position: absolute;
  top: 440px;
  left: 0;
}
.leftTopSvg {
  position: absolute;
  top: 440px;
  left: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

@-webkit-keyframes pulsing2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes pulsing2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.rightTopSvg {
  position: absolute;
  top: 440px;
  right: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.leftBottomSvg {
  position: absolute;
  top: 1500px;
  left: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.rightBottomSvg {
  position: absolute;
  top: 1500px;
  right: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.textAccentSpan {
  color: var(--accent_color);
}

.containerTextConfirm {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.textConfirm {
  margin-left: 18px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.textConfirmAccent {
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #e8a0ff;
}

.fileInput {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
}
