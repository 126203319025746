  .containerQuantity {
    z-index: 2;
    margin-bottom: 45px;
  }

  .containerAccentText {
    margin-left: 20px;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--main_color);
    margin-bottom: 20px;
  }
  
  .containerAccentTextPink {
    color: var(--accent_color);
  }

  .inputText {
    border: solid 2px transparent;
    width: 486px;
    height: 52px;
    padding-left: 20px;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 13px;
    line-height: 138%;
    color: rgba(255, 255, 255, 0.4);
    background: rgba(12, 12, 13, 0.7);
    box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    outline: none;
  }
  
  .wrapperInputText {
    position: relative;
    z-index: 10;
  }

  .inputText:focus {
    border: 2px solid #e8a0ff;
  }
  
  .inputText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 138%;
    color: rgba(255, 255, 255, 0.4);
  }
  
  .selectQuantity {
    height: 36px;
    cursor: pointer;
    padding-left: 20px;
    font-family: 'Roboto';
    padding-top: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: var(--main_color);
    border: none;
    outline: none;
  
    &:nth-child(odd) {
      background: rgba(12, 12, 13, 0.6);
      backdrop-filter: blur(5px);
    }
  
    &:nth-child(even) {
      background: rgba(12, 12, 13, 0.5);
      backdrop-filter: blur(5px);
    }
  
    &:hover {
      background: var(--accent_color);
      color: #1d1d1d;
    }
  }
  
  .containerOption {
    width: 100%;
    transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
    position: absolute;
  }

  .iconArrowQuantity {
    position: absolute;
    padding: 10px;
    left: 468px;
    top: 12px;
    fill: var(--main_color);
  }
  
  .iconArrowQuantityUp {
    position: absolute;
    padding: 10px;
    left: 468px;
    top: 12px;
    fill: var(--accent_color);
    transform: rotate(180deg);
  }
  