.label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.upload {
  position: relative;
  margin-top: 20px;
  margin-bottom: 54px;
  width: 486px;
  height: 148px;
  padding: 8px;
  background: rgba(12, 12, 13, 0.7);
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  cursor: pointer;
}

.innerText {
  border: 1px dashed rgba(255, 255, 255, 0.4);
  display: flex;
  padding: 29px 54px;
  align-items: center;
}

.iconUpload {
  fill: var(--main_color);
}

.textUpload {
  margin-left: 34px;
  width: 230px;
}

.containerFile {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 190px;
  justify-content: space-between;
}
.textAccentUpload {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

  color: #e1e1e1;

  opacity: 0.6;
  margin-top: 8px;
}
.textAccent {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.4);
}

.uploadFileInput {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
