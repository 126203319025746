.modalCookiesBackdrop {
  @media screen and (max-width: 1299px) {
    display: none;
  }
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}

.modalCookies {
  padding-top: 28px;
  padding-bottom: 28px;
  background-color: black;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10007;
}

.modalCookiesContent {
  width: 1260px;
  margin: 0 auto;
}

.modalCookiesTitle {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 24px;
}

.modalCookiesDescription {
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
}

.modalCookiesContainerCheckbox {
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: center;
}
.modalCookiesContainerCheckboxLeft {
  display: flex;
  align-items: center;
  gap: 18px;
}
.modalCheckboxText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #ffffff;
}

.containerCookiesButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.cookiesButtonLeft {
  cursor: pointer;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #323232;
  padding: 10px 16px;
  background: #e8a0ff;
  border: none;
  transition: 200ms;
  &:hover {
    color: #fff;
  }
}

.cookiesButtonRight {
  cursor: pointer;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px 16px;
  gap: 10px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #ffffff;
  transition: 200ms;
  &:hover {
    background: #e8a0ff;
    border: 1px solid #e8a0ff;
  }
}

.containerCookiesSettings {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  gap: 8px;
  transition: 200ms;
  &:hover .iconSettings {
    fill: currentColor;
    color: #e8a0ff;
  }
  &:hover .textCookiesSettings {
    text-decoration: underline;
  }
}

.textCookiesSettings {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.iconSettings {
  cursor: pointer;
  fill: #fff;
  transition: 200ms;
}

.cookiesDeclaration {
  background-color: #333333;
  border: none;
  padding-top: 21px;
  padding-bottom: 21px;
  width: 50%;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #c2c2c2;
}

.cookiesDeclarationActive {
  background-color: transparent;
  border: none;
  padding-top: 21px;
  padding-bottom: 21px;
  width: 50%;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #c2c2c2;
}

.aboutCookies {
  background-color: #333333;
  border: none;
  padding-top: 21px;
  padding-bottom: 21px;
  width: 50%;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #c2c2c2;
}

.aboutCookiesActive {
  background-color: transparent;
  border: none;
  padding-top: 21px;
  padding-bottom: 21px;
  width: 50%;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #c2c2c2;
}

.settingsBlock {
  background-color: #252525;
  margin-top: 12px;
}

.buttonImportant {
  cursor: pointer;
  padding: 6px 10px;
  background: rgba(255, 255, 255, 0.3);
  border: none;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 8px;
  text-align: center;
  color: #ffffff;
}

.buttonOptional {
  cursor: pointer;
  padding: 6px 10px;
  background: rgba(255, 255, 255, 0.3);
  border: none;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 8px;
  text-align: center;
  color: #ffffff;
}

.buttonImportantActive {
  cursor: pointer;
  padding: 6px 10px;
  background: #e8a0ff;
  border: none;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 8px;
  text-align: center;
  color: #000;
}

.buttonOptionalActive {
  cursor: pointer;
  padding: 6px 10px;
  background: #e8a0ff;
  border: none;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 8px;
  text-align: center;
  color: #000;
}

.containerCookiesDeclaration {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  padding-bottom: 11px;
}

.textAccentWithPadding {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  padding-top: 8px;
  padding-bottom: 9px;
  border-top: solid 1px #1d1d1d;
}
.containerCookiesAccent {
  display: flex;
  align-items: center;
  background-color: #1d1d1d;
  height: 34px;
}

.blockName {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 151px;
  height: 34px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  color: #ffffff;
}

.blockDomain {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 124px;
  height: 34px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  color: #ffffff;
}

.blockExpiration {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 94px;
  height: 34px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  color: #ffffff;
}

.blockDescription {
  display: flex;
  align-items: center;
  padding-left: 10px;

  height: 34px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  color: #ffffff;
}

.containerCookiesAccentDescription {
  padding-top: 10px;
  display: flex;
  text-align: start;
  background-color: #252525;
  height: 60px;
}

.blockNameDescription {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 151px;
  height: 34px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;

  color: #ffffff;
}

.blockDomainDescription {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 124px;
  height: 34px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;

  color: #ffffff;
}

.blockExpirationDescription {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 94px;
  height: 34px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
}

.blockDescriptionDescription {
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: 34px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  width: 847px;
}

.containerAboutContent {
  height: 138px;
  background-color: #252525;
  padding: 18px;
}

.containerAboutContentText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;

  margin-bottom: 18px;
}

.textBottom {
  margin-top: 3px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 12px;
  text-align: end;

  color: #ffffff;
}

.textConfirmAccent {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #ffffff;
}
