.title {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 217px;

  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: fit-content;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 35px;
    line-height: 37px;

    color: var(--main_color);
    width: fit-content;
  }
}
.titleDe {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 305px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 285px;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    font-weight: 700;
    font-size: 35px;
    line-height: 37px;

    color: var(--main_color);
    width: 375px;
  }
}

.container {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: relative;
  background-image: url('../../images/orderForm/backgroundmain.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 13475px;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #2d2e36;
}

.containerDe {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  position: relative;
  background-image: url('../../images/orderForm/backgroundmain.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 15050px;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #2d2e36;
}

.containerTitle {
  padding-top: 152px;
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  @media screen and (max-width: 833px) {
    margin-left: 80px;
  }
  @media screen and (min-width: 834px) {
    margin-bottom: 44px;
  }
  @media screen and (min-width: 1300px) {
    margin-bottom: 65px;
  }
}

.containerMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }
  min-height: 100vh;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #1e1e1e;
  overflow: hidden;
}
.containerMobileDe {
  @media screen and (min-width: 833px) {
    display: none;
  }
  min-height: 100vh;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #1e1e1e;
  overflow: hidden;
}

.containerTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  min-height: 100vh;
  height: 17682px;
  position: relative;
  width: 100%;
  background-color: #1e1e1e;
}

.containerTabletDe {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  min-height: 100vh;
  height: 20100px;
  position: relative;
  width: 100%;
  background-color: #1e1e1e;
  width: 100%;
}
.wrapper {
  position: relative;
  margin-left: calc(50% - 165px);
  width: 331px;
  padding-bottom: 190px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 754px;
    margin-left: calc(50% - 375px);
    padding-bottom: 50px;
  }

  @media screen and (min-width: 1300px) {
    margin-left: calc(50% - 620px);
    width: 1240px;
    padding-bottom: 50px;
  }
}

.text {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.textContact {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 18px;
}

.textAccent {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 80px;
}

.containerText {
  margin-bottom: 40px;
}

.textTitle {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.description {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.wrapperText {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.containerSize {
  width: 550px;
}

@-webkit-keyframes pulsing2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes pulsing2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.leftTopSvg {
  position: absolute;
  top: 440px;
  left: 0;
}
.leftTopSvg {
  position: absolute;
  top: 440px;
  left: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.rightTopSvg {
  position: absolute;
  top: 440px;
  right: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.leftBottomSvg {
  position: absolute;
  top: 1500px;
  left: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.rightBottomSvg {
  position: absolute;
  top: 1500px;
  right: 0;
  -webkit-animation: pulsing2 10s infinite;
  animation: pulsing2 10s infinite;
}

.titleAccent {
  margin-top: 70px;
  margin-bottom: 30px;
  font-family: 'Titillium Web';
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #e8a0ff;
  @media screen and (min-width: 834px) {
    font-size: 22px;
    margin-top: 60px;
  }
}

.containerMarker {
  display: flex;
  margin-left: 16px;
  margin-bottom: 20px;
}

.textList {
  width: fit-content;
  margin-left: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.linkAccent {
  color: rgba(232, 160, 255, 1);
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 26px;
}

.flex {
  display: flex;
  align-items: center;
}

.textWeb {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  margin-right: 5px;
}

.buttonAgree {
  cursor: pointer;
  margin-left: 14px;
  background-color: transparent;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.buttonAgreeText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.containerAgree {
  display: flex;
  align-items: center;
}

.backToForm {
  cursor: pointer;
  margin-top: 54px;
  padding: 14px 49px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #ffffff;
  backdrop-filter: blur(7.5px);
  &:hover,
  &:focus {
    color: #1d1d1d;
    background: #e8a0ff;
    box-shadow: 0px 2px 10px 2px rgba(63, 68, 71, 0.18);
  }
  &:active {
    background: #e8a0ff;
    border: 2px solid #b66fcd;
    box-shadow: 0px 0px 8px rgba(120, 29, 149, 0.3);
  }
}
