@import-normalize; /* bring in normalize.css styles */

:root {
  --font: 11px;
  --accent_color: #e8a0ff;
  --main_color: #ffffff;
}

body {
  margin: 0;
  /* font-family: Rubik;
  color: #fbfbfb;
  background-color: #001714; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

textarea {
  resize: none;
  padding: 0;
}

input {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: FontAwesome;
  src: url('./fonts/fontawesome.woff');
}

@font-face {
  font-family: Circular;
  src: url('./fonts/Circular-Book.woff2');
}

@font-face {
  font-family: Icomoon;
  src: url('./fonts/icomoon.woff');
}

@font-face {
  font-family: Memv;
  src: url('./fonts/memv-font.woff2');
}

@font-face {
  font-family: TitilliumWeb;
  src: url('./fonts/TitilliumWeb-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Titillium Web;
  src: url('./fonts/TitilliumWeb-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url('./fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

.MuiPaper-root {
  border-radius: 15px !important;
}

.MuiCalendarPicker-root {
  background-color: #43434a;
  border-radius: 10px !important;
}

.MuiPickersDay-root {
  background-color: #161619 !important;
  color: white !important;
}

.MuiPickersDay-root:hover {
  background-color: #36363f !important;
}
.Mui-disabled {
  background-color: #36363f !important;
}

.MuiPickersDay-root.Mui-selected {
  color: #761d94 !important;
}

.MuiPickersCalendarHeader-root,
.MuiPickersCalendarHeader-switchViewIcon,
.MuiSvgIcon-fontSizeMedium {
  color: white;
}
