.container {
  margin-top: 30px;
  margin-bottom: 19px;
  display: flex;
}

.img {
  width: 378px;
  height: 320px;
}
.title {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: #e8a0ff;
  margin-bottom: 60px;
}
.titleAccent {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: #e8a0ff;
}

.containerLeft {
  width: 366px;
  height: 366px;

  @media screen and (min-width: 1300px) {
    margin-left: -100px;
    height: 341px;
  }
}

.containerRight {
  @media screen and (min-width: 834px) {
    padding-left: 25px;
    padding-right: 10px;
  }
}

.smallImg {
  height: 37px;
  width: 35px;
}

.descriptionSmallImg {
  margin-left: 20px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.containerImg {
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.containerIconBack {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: rgba($color: #fff, $alpha: 0.1);
  margin-left: -5px;
  transition: all 300ms;
  &:hover .containerIcon {
    background-color: var(--accent_color);
  }
}
.containerIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  background-color: #fff;
  transition: all 300ms;
  &:hover {
    transition: all 300ms;
  }
}

.containerPlay {
  position: relative;
  align-items: center;
  display: flex;
}

.border {
  position: absolute;
  top: 3px;
  left: -18px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid var(--accent_color);
  opacity: 0;
  -webkit-animation: pulse-2 2.6s cubic-bezier(0.25, 0.9, 0.2, 1) 1s infinite;

  @keyframes pulse-2 {
    0% {
      opacity: 1;
      -webkit-transform: scale(0.5, 0.5);
    }
  }
}
.border1 {
  position: absolute;
  top: 3px;
  left: -18px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid var(--accent_color);
  opacity: 0;
  -webkit-animation: pulse-2 2.6s cubic-bezier(0.25, 0.9, 0.2, 1) 1.3s infinite;

  @keyframes pulse-2 {
    0% {
      opacity: 1;
      -webkit-transform: scale(0.5, 0.5);
    }
  }
}

.icon {
  fill: rgb(45, 44, 44);
  margin-left: 2px;
}

.link {
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  &:focus-visible {
    outline: none;
  }
}

.iconSmall {
  fill: var(--accent_color);
}

.containerSmallIcon {
  width: 37px;
  justify-content: center;
}
