.container {
  @media screen and (max-width: 1300px) {
    display: none;
  }
  background-image: url(../../images/partsSpectrum/backNew1.jpg);
  width: 100%;
  height: calc(110vh);
  min-height: 1070px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.containerMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }

  background-image: url(../../images/partsSpectrum/mobile/backNew.jpg);
  width: 100%;
  height: calc(95vh);
  min-height: 850px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.containerTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }
  background-image: url(../../images/partsSpectrum/tablet/backNew.jpg);
  width: 100%;
  height: calc(85vh);
  min-height: 720px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.containerWrapper {
  padding-top: 65px;
  display: flex;
  justify-content: center;
}

.wrapper {
  width: 331px;
  display: flex;
  margin-left: calc(50% - 331px / 2);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 444px;
    display: flex;
    margin-left: calc(50% - 360px);
  }

  @media screen and (min-width: 1300px) {
    width: 1240px;
    display: flex;
    margin-left: calc(50% - 1240px / 2);
  }
}

.containerText {
  margin-top: 33px;
  width: 503px;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 44px;
    width: 503px;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media screen and (min-width: 1300px) {
    margin-top: 100px;
    width: 503px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.title {
  position: absolute;
  top: 30px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 40px;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    position: absolute;
    top: 0;
    margin-top: 58px;
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }
}
.text {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  margin-bottom: 15px;
  color: #1d1d1d;

  @media screen and (min-width: 1300px) {
    text-align: justify;
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
  }
}

.textAccent {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  margin-bottom: 15px;
  padding-bottom: 15px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    text-align: justify;
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
  }
}
.textVideo {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1d1d1d;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    font-family: Titillium Web;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: #0d0d0d;
  }
}

.containerIconBack {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: rgba($color: #fff, $alpha: 0.1);
  margin-right: 10px;
  &:hover .containerIcon {
    background-color: var(--accent_color);
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: rgba($color: #fff, $alpha: 0.1);
    margin-right: 20px;
  }
}
.containerIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  background-color: #fff;
  transition: all 300ms;
  &:hover {
    transition: all 300ms;
  }
}

.containerPlay {
  position: relative;
  align-items: center;
  display: flex;
  margin-left: -10px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    position: relative;
    align-items: center;
    display: flex;
  }
}

.border {
  position: absolute;
  top: -17px;
  left: -13px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid var(--accent_color);
  opacity: 0;
  -webkit-animation: pulse-2 2.6s cubic-bezier(0.25, 0.9, 0.2, 1) 1s infinite;

  @keyframes pulse-2 {
    0% {
      opacity: 1;
      -webkit-transform: scale(0.5, 0.5);
    }
  }
}
.border1 {
  position: absolute;
  top: -17px;
  left: -13px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid var(--accent_color);
  opacity: 0;
  -webkit-animation: pulse-2 2.6s cubic-bezier(0.25, 0.9, 0.2, 1) 1.3s infinite;

  @keyframes pulse-2 {
    0% {
      opacity: 1;
      -webkit-transform: scale(0.5, 0.5);
    }
  }
}

.icon {
  fill: rgb(135, 132, 132);
  margin-left: 2px;
}

.link {
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus-visible {
    outline: none;
  }
}
