.containerMilling {
  @media screen and (max-width: 1300px) {
    display: none;
  }

  width: 100%;
  min-height: 1170px;
  background-image: url('../../images/milling/images.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.containerMillingMobile {
  @media screen and (min-width: 833px) {
    display: none;
  }

  width: 100%;
  min-height: 1750px;
  background-image: url('../../images/milling/mobile/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  min-height: fit-content;
}

.containerText {
  margin: 0 auto;
  margin-top: 33px;
  max-width: 754px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 40px;
    left: 165px;
    font-weight: 600;
    font-size: 26px;
    line-height: 50px;
  }

  @media screen and (min-width: 1300px) {
    margin: 0 auto;
    width: 1010px;
    padding: 28px 95px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
    margin-bottom: 50px;
    margin-top: 50px;
  }
}
.containerTextAccent {
  margin: 0 auto;
  margin-top: 60px;
  max-width: 754px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  @media screen and (max-width: 833px) {
    margin-bottom: 80px;
  }
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 40px;
    left: 165px;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    margin-top: 50px;
  }

  @media screen and (min-width: 1300px) {
    margin: 0 auto;
    margin-top: 50px;
    width: 910px;
    padding: 28px 95px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
  }
}

.description {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  text-align: center;
}

.line {
  margin-top: 30px;
  border-top: 3px solid #781d95;
}

.containerMillingTablet {
  @media screen and (max-width: 833px) {
    display: none;
  }
  @media screen and (min-width: 1300px) {
    display: none;
  }

  width: 100%;
  min-height: 1300px;
  background-image: url('../../images/milling/tablet/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.containerWrapper {
  position: relative;
  margin-top: 63px;
  margin-left: calc(50% - 360px / 2);
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    margin-top: 24px;
    margin-left: calc(50% - 480px / 2);
  }

  @media screen and (min-width: 1300px) {
    margin-top: 60px;
    margin-left: calc(50% - 280px);
  }
}
.flipContainer {
  @media screen and (max-width: 833px) {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
  display: block;
  margin-left: calc(50% - 330px / 2);
  width: 330px;
  padding-top: 30px;
  padding-bottom: 30px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    width: 668px;
    display: flex;
    justify-content: center;
    margin-left: calc(50% - 668px / 2);
    flex-wrap: wrap;
    margin-top: 50px;
    padding-bottom: 20px;
  }

  @media screen and (min-width: 1300px) {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    width: 1144px;
    display: flex;
    margin-left: calc(50% - 1144px / 2);
  }
}

.cardMobile {
  position: relative;
  cursor: pointer;
  perspective: 1500px;
}

.card {
  position: relative;
  cursor: pointer;
  perspective: 1500px;

  &:hover .flipper {
    transform: rotateY(-180deg);
  }
}

.flip {
  transform: rotateY(-180deg);
}

.flipper {
  margin: 30px;
  margin-right: 38px;
  margin-left: 38px;
  margin-top: 0;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.flipper1 {
  margin: 30px;
  margin-right: 38px;
  margin-left: 38px;
  margin-top: 0;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  width: 100%;
  backface-visibility: hidden;
}

.back {
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(-180deg);
}

.title {
  position: absolute;
  top: 35px;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    top: 50px;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: 1300px) {
    position: absolute;
    top: 0;
    margin-top: 58px;
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }
}

.containerFrontText {
  position: absolute;
  left: 0;
  top: 35px;
  width: 255px;
  text-align: center;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
    left: 0;
    top: 35px;
    width: 250px;
    text-align: center;
  }

  @media screen and (min-width: 1300px) {
    left: 0;
    top: 35px;
    width: 305px;
    text-align: center;
  }
}

.icon {
  fill: var(--accent_color);
}

.titleText {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    color: #fbfbfb;
    letter-spacing: 0px;
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
  }
}

.containerBackText {
  position: absolute;
  left: 0;
  top: 35px;
  width: 220px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (min-width: 834px) and (max-width: 1299px) {
  }

  @media screen and (min-width: 1300px) {
    position: absolute;
    left: 0;
    top: 35px;
    width: 265px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.text {
  font-family: 'Titillium Web';
  height: 220px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  text-shadow: 1.34858px 2.69716px 5.39432px rgba(0, 0, 0, 0.15);
}

.link {
  display: block;
  margin-top: 40px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #fbfbfb;
  transition: color 300ms cubic-bezier(0.25, 1, 0.33, 1);

  &:hover {
    color: var(--accent_color);
  }
}

.wrapperButton {
  top: -60px;
  position: relative;
  display: flex;
  justify-content: center;

  &:hover .iconButtonHover {
    fill: #e8a0ff;
    stroke: transparent;
  }
  &:active .iconButtonHover {
    fill: #e8a0ff;
    stroke: #b66fcd;
    stroke-width: 2px;
  }
}
.iconButtonHover {
  position: absolute;
  fill: rgba(255, 255, 255, 0.3);
  stroke: #fff;
  stroke-width: 1px;
}

.link1 {
  position: absolute;
  z-index: 10;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #ffffff;
  margin-top: 8px;
}
