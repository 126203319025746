  .text {
    margin-left: 20px;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 122%;
    color: var(--main_color);
  }
  
  .inputTextCountry {
    position: relative;
    width: 456px;
    height: 52px;
    padding-left: 50px;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 13px;
    line-height: 138%;
    color: rgba(255, 255, 255, 0.4);
    background: rgba(12, 12, 13, 0.7);
    box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    border: none;
    outline: none;
  }
  
  .containerBottom {
    position: relative;
    margin-top: 20px;
  }

  .containerOption {
    width: 100%;
    transition: all 600ms cubic-bezier(0.25, 1, 0.33, 1);
    position: absolute;
  }

  .select {
    height: 36px;
    cursor: pointer;
    padding-left: 20px;
    font-family: 'Roboto';
    padding-top: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: var(--main_color);
    border: none;
    outline: none;
    &:nth-child(odd) {
      background: rgba(12, 12, 13, 0.6);
      backdrop-filter: blur(5px);
    }
  
    &:nth-child(even) {
      background: rgba(12, 12, 13, 0.5);
      backdrop-filter: blur(5px);
    }
  
    &:hover {
      background: var(--accent_color);
      color: #1d1d1d;
    }
  }
  
  .countryFlag {
    position: absolute;
    top: 53px;
    display: flex;
    width: 27px;
  
    :global {
      .PhoneInputInput {
        display: none;
      }
    }
  }
  
  .flag {
    position: absolute;
    top: -38px;
    left: 18px;
  }

  .countrySelect {
    max-height: 227px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 505px;
  }
  
  .countrySelect::-webkit-scrollbar-thumb {
    background-color: var(--accent_color);
    border-radius: 5px;
  }

  .countrySelect::-webkit-scrollbar {
    width: 5px;
    background-color: #000;
  }
  
  .iconArrowQuantity {
    position: absolute;
    padding: 10px;
    left: 468px;
    top: 12px;
    fill: var(--main_color);
  }
  
  .iconArrowQuantityUp {
    position: absolute;
    padding: 10px;
    left: 468px;
    top: 12px;
    fill: var(--accent_color);
    transform: rotate(180deg);
  }
  
  .countryArrow {
    cursor: pointer;
    left: 470px;
    top: -43px;
  }
  
  .countryDelete {
    top: -47px;
    left: 466px;
    cursor: pointer;
  }